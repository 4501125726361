<footer class="footer-area bg-fbf9f8">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"
                            ><img src="assets/img/logo.png" alt="image"
                        /></a>
                    </div>
                    <p>
                        تجمع شركة البرامج المفتوحة لتقنية المعلومات (OSIT)
                        مجموعة من العقول المتحمسة لتحويل رؤيتك إلى واقع يمنح
                        المستخدمين تجربة رقمية رائعة. بدءًا من تصميم مواقع الويب
                        والتطوير وتطبيقات الأجهزة المحمولة القابلة للتطويروتنفيذ
                        تخطيط موارد المؤسسات (ERP) ووصولا الى منصات SaaS والحلول
                        الذي نقوم بتنفيذها يسرع الكفاءة ويحفز النمو لعملائنا.
                    </p>
                    <ul class="social-links">
                        <li>
                            <a href="https://www.facebook.com/osit.com.sa/"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/osit_sa"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/osit_sa/"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/osit-sa/"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>الحلول</h3>
                    <ul class="services-list">
                        <li><a routerLink="/odoo-solution">Odoo</a></li>
                        <li>
                            <a routerLink="/openbravo-solution">Openbravo</a>
                        </li>
                        <li>
                            <a routerLink="/hr-solution"
                                >إدارة الموارد البشرية HR</a
                            >
                        </li>
                        <li>
                            <a routerLink="/pos-solution"> نقاط البيع (POS)</a>
                        </li>
                        <li>
                            <a routerLink="/hospital-solution"
                                >نظام إدارة المستشفيات</a
                            >
                        </li>
                        <li>
                            <a routerLink="/logistics-solution"
                                >نظام إدارة الخدمات اللوجستية</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>روابط مهمة</h3>
                    <ul class="usefull-links">
                        <li><a routerLink="/about">من نحن</a></li>
                        <li><a routerLink="/services">خدماتنا</a></li>
                        <li><a routerLink="/blogs">المدونة</a></li>
                        <li>
                            <a href="https://support.osit.com.sa"
                                >الدعم الفني</a
                            >
                        </li>
                        <li>
                            <a routerLink="/quotation-request"
                                >احصل على عرض سعر</a
                            >
                        </li>
                        <li><a routerLink="/career">التوظيف</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>للتواصل</h3>
                    <ul class="contact-list">
                        <li>
                            <span>العنوان:</span> 7843 طريق الدائري الشرقي الفرعي
                            ، الريان ، الرياض 2294-14211 <br /> المملكة العربية السعودية
                        </li>
                        <li>
                            <span>الموقع:</span>
                            <a routerLink="/">www.osit.com.sa</a>
                        </li>
                        <li>
                            <span>البريد الإلكتروني:</span>
                            <a href="mailto:sales@osit.com.sa">
                                sales@osit.com.sa
                            </a>
                        </li>
                        <li>
                            <span>الهاتف:</span>
                            <a href="tel:920006748">920006748</a>
                        </li>
                        <li>
                            <span>فاكس:</span>
                            <a href="tel:+966114917224"> 11 49 172 24 (+966)</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© كل الحقوق محفوظة لدى OSIT</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li>
                            <a routerLink="/terms-condition"
                                >الشروط و الأحكام</a
                            >
                        </li>
                        <li>
                            <a routerLink="/privacy-policy">سياسة الخصوصية</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="icofont-stylish-up"></i></div>
