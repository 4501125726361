<div class="page-title animatedBackground">
    <div class="container">
        <h3>تواصل معنا</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>تواصل معنا</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-box">
                    <div class="icon">
                        <i class="icofont-phone"></i>
                    </div>
                    <div class="content">
                        <h3>الهاتف / الفاكس</h3>
                        <p><a href="tel:920006748">920006748</a></p>
                        <p><a href="tel:+966114917224">(+966) 11 49 172 24</a></p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="contact-box">
                    <div class="icon">
                        <i class="icofont-envelope"></i>
                    </div>
                    <div class="content">
                        <h3>البريد الإلكتروني</h3>
                        <p><a href="mailto:sales@osit.com.sa">sales@osit.com.sa </a></p>
                        <p><a href="mailto:support@osit.com.sa">support@osit.com.sa</a></p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-box">
                    <div class="icon">
                        <i class="icofont-google-map"></i>
                    </div>
                    <div class="content">
                        <h3>الموقع</h3>
                        <p>7843 طريق الدوار الشرقي , الريان الرياض 14211-2294</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12 col-md-12">
                <div id="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1812.2246821597907!2d46.765322663445524!3d24.711448552471804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f01464c51ec8b%3A0x961401f23e215927!2z2LTYsdmD2Kkg2KfZhNio2LHYp9mF2Kwg2KfZhNmF2YHYqtmI2K3YqQ!5e0!3m2!1sar!2ssa!4v1519731826443"></iframe>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="contact-text">
                    <h3>ابقى على تواصل</h3>
                    <p>تجمع شركة البرامج المفتوحة لتقنية المعلومات (OSIT) مجموعة من العقول المتحمسة لتحويل رؤيتك إلى واقع يمنح المستخدمين تجربة رقمية رائعة بدءًا من تصميم مواقع الويب  والتطوير  وتطبيقات الأجهزة المحمولة القابلة للتطوير  وتنفيذ تخطيط موارد المؤسسات (ERP) ووصولا الى منصات SaaS والحلول التي نقوم بتنفيذها يسرع الكفاءة ويحفز النمو لعملائنا.</p>
                    <p>أياً كان سؤالك يسعدنا أن نسمع منك.</p>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/osit.com.sa/" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="https://twitter.com/osit_sa" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/osit_sa/" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/osit-sa/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <input type="text" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="الإسم">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="البريد الإلكتروني">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" [(ngModel)]="subject" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="الموضوع">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" [(ngModel)]="message" [ngModelOptions]="{standalone: true}"  class="form-control" id="message" cols="30" rows="4" placeholder="رسالتك"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button (click)="onSubmit()" type="submit" class="btn btn-primary">ارسال</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>