<div class="page-title animatedBackground">
    <div class="container">
        <h3>Openbravo</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/our-solutions">حلولنا</a></li>
            <li class="dot-divider"></li>
            <li>Openbravo</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>عزز وتيرة اعمالك و قم بتسريع رحلتك التجارية</h3>
                <p>من صيانة البيانات الرئيسية إلى تخطيط البضائع وتوزيعها ، يسمح Openbravo بتقديم المنتجات بشكل أسرع وأكثر دقة بالإضافة إلى عمليات التخصيص والتجديد بفعالية اكبر.</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> بسّط ادارة متاجرك المتعددة من خلال لوحة تحكم واحدة</h4>
                    <p>من خلال اعتماد نظام  Openbravo    تكتسب الشركات مرونة أكبر وقابلية للتوسع عبر المواقع  وتحسينات تشغيلية كبيرة في مجالات عديدة  مثل اجراء الإعدادات بشكل مبسط وسريع للمتاجر الجديدة وامكانية اضافة متاجر متعدده بشكل مبسط  وإدارة المنتجات و ادارة التسعير ، وإمكانية رؤية المبيعات والمخزون في الوقت الفعلي عبر نظام الأوبن برافو  بشكل كامل  . </p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تفعيل الادارة الذكية  للمستودعات </h4>
                    <p>قم بتفعيل عملك لتحقيق التميز التشغيلي في المستودعات وتحسين ادارة العمليات والجرد وتحقيق النجاح في مختلف المجالات.</p>
                </div>
            </div>

            <div class="services-details-image">
                <img style="border-radius:25px ;" src="assets/img/openbravo.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/openbravo2.png"  style="border-radius:25px ;" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>الميزات</h3>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تخطيط البضائع</h4>
                    <h4><i class="icofont-check-alt"></i> توزيع البضائع</h4>
                    <h4><i class="icofont-check-alt"></i> إدارة التصنيع</h4>
                    <h4><i class="icofont-check-alt"></i> إدارة المستودعات و المخزون</h4>
                    <h4><i class="icofont-check-alt"></i> المشتريات</h4>
                    <h4><i class="icofont-check-alt"></i> إدارة الشحن والنقل</h4>
                    <h4><i class="icofont-check-alt"></i> المحاسبة والمالية</h4>
                    
                </div>
                
            </div>
        </div>
    </div>
</section>

