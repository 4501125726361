<div class="repair-main-banner">
    <div class="container">
        <div class="repair-content-slides owl-carousel owl-theme">
            <div class="repair-banner-content">
                <h2>Your Local Computer Repair Experts</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                <a routerLink="#" class="btn btn-primary">Get a Quote</a>
            </div>

            <div class="repair-banner-content">
                <h2>Your Local Computer Repair Experts</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                <a routerLink="#" class="btn btn-primary">Get a Quote</a>
            </div>

            <div class="repair-banner-content">
                <h2>Your Local Computer Repair Experts</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua.</p>
                <a routerLink="#" class="btn btn-primary">Get a Quote</a>
            </div>
        </div>
    </div>
</div>

<section class="repair-boxes-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-repair-box">
                    <i class="icofont-laptop-alt"></i>
                    <h3>Laptop Repair</h3>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="#" class="read-more-btn">Read More</a>
                    <img src="assets/img/shape-one.png" class="left" alt="image">
                    <img src="assets/img/shape-two.png" class="right" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-repair-box">
                    <i class="icofont-imac"></i>
                    <h3>PC Mac Computers</h3>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="#" class="read-more-btn">Read More</a>
                    <img src="assets/img/shape-one.png" class="left" alt="image">
                    <img src="assets/img/shape-two.png" class="right" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                <div class="single-repair-box">
                    <i class="icofont-smart-phone"></i>
                    <h3>Smartphone Repair</h3>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="#" class="read-more-btn">Read More</a>
                    <img src="assets/img/shape-one.png" class="left" alt="image">
                    <img src="assets/img/shape-two.png" class="right" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="repair-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="repair-about-image">
                    <img src="assets/img/about-one.jpg" alt="image">
                    <img src="assets/img/about-two.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="repair-about-content">
                    <div class="section-title">
                        <span>About Us</span>
                        <h3>We have 35 years of experience in repair services</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-about-box">
                                <i class="icofont-users"></i>
                                <h3>Experienced Professionals</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-about-box">
                                <i class="icofont-mouse"></i>
                                <h3>Expert Technical Skills</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-about-box">
                                <i class="icofont-repair"></i>
                                <h3>Excellent Reputation </h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-about-box">
                                <i class="icofont-handshake-deal"></i>
                                <h3>Friendly Service</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                            </div>
                        </div>
                    </div>
                    <p><i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</i></p>
                    <a routerLink="#" class="read-more-btn">Read More About Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="repair-services-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Services</span>
            <h3>What we offer for you</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
        </div>
        <div class="repair-services-slides owl-carousel owl-theme">
            <div class="single-repair-services">
                <i class="icofont-laptop-alt"></i>
                <h3>Laptop Repair</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                <a routerLink="#" class="read-more-btn">Read More</a>
            </div>

            <div class="single-repair-services">
                <i class="icofont-computer"></i>
                <h3>Computer Repair</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                <a routerLink="#" class="read-more-btn">Read More</a>
            </div>

            <div class="single-repair-services">
                <i class="icofont-responsive"></i>
                <h3>Apple Products Repair</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                <a routerLink="#" class="read-more-btn">Read More</a>
            </div>

            <div class="single-repair-services">
                <i class="icofont-ui-touch-phone"></i>
                <h3>Mobile Repair</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                <a routerLink="#" class="read-more-btn">Read More</a>
            </div>
        </div>
        <div class="repair-services-inner">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="single-inner-services">
                        <i class="icofont-handshake-deal"></i>
                        <h3>Quick Repair Process</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="single-inner-services">
                        <i class="icofont-wrench"></i>
                        <h3>Free Diagnostics</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="single-inner-services">
                        <i class="icofont-fast-delivery"></i>
                        <h3>Fast Delivery</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h3>What customer say about us</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
        </div>
        <div class="repair-feedback-slides owl-carousel owl-theme">
            <div class="single-repair-feedback">
                <div class="client-info">
                    <img src="assets/img/client-avatar1.jpg" alt="image">
                    <h3>Eva Lucy</h3>
                    <span>CEO at Renovation PVT. Ltd.</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <ul>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                </ul>
            </div>

            <div class="single-repair-feedback">
                <div class="client-info">
                    <img src="assets/img/client-avatar2.jpg" alt="image">
                    <h3>Michel Jonson</h3>
                    <span>CEO at EnvyTheme.com</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <ul>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                </ul>
            </div>

            <div class="single-repair-feedback">
                <div class="client-info">
                    <img src="assets/img/client-avatar3.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at Renovation PVT. Ltd.</span>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <ul>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                    <li><i class="icofont-star"></i></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="gallery-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="section-title text-center">
            <span>Recent Projects</span>
            <h3>Our work photo gallery</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
        </div>
        <div class="shorting-menu gallery-shorting-menu">
            <button class="filter" data-filter="all"><i class="icofont-wall"></i> All</button>
            <button class="filter" data-filter=".laptop">Laptop</button>
            <button class="filter" data-filter=".desktop">Desktop</button>
            <button class="filter" data-filter=".smartphone">Smartphone</button>
            <button class="filter" data-filter=".tablet">Tablet</button>
            <button class="filter" data-filter=".hardware">Hardware</button>
        </div>
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 mix laptop">
                    <div class="single-gallery-item">
                        <a href="assets/img/gallery-one.jpg" class="popup-btn">
                            <img src="assets/img/gallery-one.jpg" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mix desktop">
                    <div class="single-gallery-item">
                        <a href="assets/img/gallery-two.jpg" class="popup-btn">
                            <img src="assets/img/gallery-two.jpg" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mix smartphone">
                    <div class="single-gallery-item">
                        <a href="assets/img/gallery-three.jpg" class="popup-btn">
                            <img src="assets/img/gallery-three.jpg" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mix tablet">
                    <div class="single-gallery-item">
                        <a href="assets/img/gallery-four.jpg" class="popup-btn">
                            <img src="assets/img/gallery-four.jpg" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mix hardware">
                    <div class="single-gallery-item">
                        <a href="assets/img/gallery-five.jpg" class="popup-btn">
                            <img src="assets/img/gallery-five.jpg" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 mix smartphone">
                    <div class="single-gallery-item">
                        <a href="assets/img/gallery-six.jpg" class="popup-btn">
                            <img src="assets/img/gallery-six.jpg" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="repair-partner-area">
    <div class="container">
        <div class="repair-partner-slides owl-carousel owl-theme">
            <div class="single-repair-client">
                <a routerLink="#">
                    <img src="assets/img/partner-img1.png" alt="image">
                </a>
            </div>
            <div class="single-repair-client">
                <a routerLink="#">
                    <img src="assets/img/partner-img2.png" alt="image">
                </a>
            </div>
            <div class="single-repair-client">
                <a routerLink="#">
                    <img src="assets/img/partner-img3.png" alt="image">
                </a>
            </div>
            <div class="single-repair-client">
                <a routerLink="#">
                    <img src="assets/img/partner-img4.png" alt="image">
                </a>
            </div>
            <div class="single-repair-client">
                <a routerLink="#">
                    <img src="assets/img/partner-img5.png" alt="image">
                </a>
            </div>
            <div class="single-repair-client">
                <a routerLink="#">
                    <img src="assets/img/partner-img6.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>

<section class="repair-blog-area ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Our blog</span>
            <h3>Recent Turacos news</h3>
            <p>We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-repair-blog-post">
                    <div class="blog-image">
                        <a routerLink="#"><img src="assets/img/blog-one.jpg" alt="image"></a>
                        <div class="tags"><a href="#">Technology</a></div>
                    </div>

                    <div class="blog-content">
                        <h3><a routerLink="#">Where and How to watch live stream today</a></h3>
                        <ul>
                            <li>July 10, 2019</li>
                            <li>by: <a href="#">admin</a></li>
                            <li><a routerLink="#" class="read-more-btn">Read More</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-blog-post">
                    <div class="blog-image">
                        <a routerLink="#"><img src="assets/img/blog-two.jpg" alt="image"></a>
                        <div class="tags"><a href="#">Technology</a></div>
                    </div>

                    <div class="blog-content">
                        <h3><a routerLink="#">TikTok illegally collecting data, sharing with China</a></h3>
                        <ul>
                            <li>July 10, 2019</li>
                            <li>by: <a href="#">admin</a></li>
                            <li><a routerLink="#" class="read-more-btn">Read More</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-repair-blog-post">
                    <div class="blog-image">
                        <a routerLink="#"><img src="assets/img/blog-three.jpg" alt="image"></a>
                        <div class="tags"><a href="#">Technology</a></div>
                    </div>

                    <div class="blog-content">
                        <h3><a routerLink="#">The five devices you need to work anytime and anywhere</a></h3>
                        <ul>
                            <li>July 10, 2019</li>
                            <li>by: <a href="#">admin</a></li>
                            <li><a routerLink="#" class="read-more-btn">Read More</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>