<div class="page-title animatedBackground">
    <div class="container">
        <h3>تطوير تطبيقات الويب</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/services">الخدمات</a></li>
            <li class="dot-divider"></li>
            <li>تطوير تطبيقات الويب</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">

                <h3> خدمات تطوير الويب الرائدة لتعزيز نمو أعمالك </h3>
                <p>نحن نساعد عملائنا على تطوير وتسهيل اعمالهم عن طريق  الأجهزة المحمولة وتحويل الأنظمة القديمة إلى تطبيقات حديثة عبر الأنظمة الأساسية. بالاعتماد على خبرتهم الكبيرة في الواجهات الجمالية  يقوم مطورين تطبيقات الويب لدينا بتنفيذ سيناريوهات تطوير تطبيقات الويب الأكثر طلبًا:</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> ابتكار حلول حيادية للأجهزة</h4>
                    <p>نحن نقدم تطبيقات HTML5 / JavaScript تؤدي غرضها على اكمل وجه
                        مع واجهات مريحة وجذابة كما توفر للمستخدمين تجربة مثالية عبر أجهزة الكمبيوتر المكتبية والهواتف الذكية والأجهزة اللوحية.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> إعادة تطوير التطبيقات القديمة</h4>
                    <p>يمكننا تحديث أو إعادة تصميم واجهات الويب القديمة بالكامل بطريقة حديثة من أجل موائمتها مع الاحتياجات المتطورة للمستخدمين والتقنيات الحالية.</p>
                </div>
            
            </div>

            <div class="services-details-image">
                <img src="assets/img/custom-development.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/web-application-development.png" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>نحن نخطو خطوة مستبقة لتطوير تطبيقات الويب الخاصة بك التي تم التخطيط لها جيدًا وقابلة للتطوير بدرجة كبيرة وآمنة</h3>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تصميم المواقع الإبداعية </h4>
                    <h4><i class="icofont-check-alt"></i> بوابات الويب B2B و B2C</h4>
                    <h4><i class="icofont-check-alt"></i> المجتمعات الإفتراضية</h4>
                    <h4><i class="icofont-check-alt"></i> المواقع التي يتم تشغيلها باستخدام قواعد البيانات</h4>
                    <h4><i class="icofont-check-alt"></i> الاستجابة السريعة</h4>
                    <h4><i class="icofont-check-alt"></i> البوابات الداخلية</h4>
                </div>
               
            </div>
        </div>
    </div>
</section>
