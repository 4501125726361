<div class="page-title animatedBackground">
    <div class="container">
        <h3>من نحن</h3>
        <ul>
            <li><a router="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>من نحن</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>



<section class="about-area ptb-100 bg-fbf9f8">
    <div class="container">
       
        <div class="row  align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="features-text">
                    <h3>عن OSIT</h3>
                   <p>تجمع شركة البرامج المفتوحة لتقنية المعلومات (OSIT) مجموعة من العقول المتحمسة لتحويل رؤيتك إلى واقع يمنح المستخدمين تجربة رقمية رائعة. بدءًا من تصميم مواقع الويب  والتطوير  وتطبيقات الأجهزة المحمولة القابلة للتطويروتنفيذ تخطيط موارد المؤسسات (ERP) ووصولا الى منصات SaaS والحلول الذي نقوم بتنفيذها يسرع الكفاءة ويحفز النمو لعملائنا.</p>
            <p>&nbsp;<br></p>
                   <p>من عام 2012  نحن ملتزمون بتحقيق ودفع مستوى الأداء في جميع الأوقات من خلال الاستفادة من تطور التكنولوجيا و نقدم حلولاً شاملة للشركات الناشئة الممولة بملايين الريالات للشركات الصغيرة والمتوسطة. وجود مكتبنا في الرياض هو  انعكاس حقيقي لمصداقيتنا .</p>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="img mb-0">
                    <img style="border-radius:25px;" src="assets/img/about_us.png" alt="image">
                </div>
            </div>
        </div>
        <section class="about-area ptb-100 bg-fbf9f8">
            <div class="container">
               
        
                <div class="about-inner-area">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="row about-image">
                                <div class="col-6 col-lg-6 col-md-6">
                                    <div class="image">
                                        <img src="assets/img/home2.jpg" alt="about">
                                    </div>
                                </div>
                                <div class="col-6 col-lg-6 col-md-6">
                                    <div class="image">
                                        <img src="assets/img/home1.jpg" alt="about">
                                    </div>
                                </div>
                                <div class="col-6 col-lg-6 col-md-6">
                                    <div class="image mt-30">
                                        <img src="assets/img/home3.jpg" alt="about">
                                    </div>
                                </div>
                                <div class="col-6 col-lg-6 col-md-6">
                                    <div class="image mt-30">
                                        <img src="assets/img/home4.jpg" alt="about">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="about-text">
                                <span>شراكة سعودية للابتكار التكنولوجي</span>
                                <h3>لماذا OSIT ؟</h3>
                                <ul style="font-style:italic">
                                 <li>   نقوم بتحليل وتطوير وتنفيذ حلول الأعمال المتعلقة بالتقنيات التي تساعد الشركات العالمية على التركيز على أعمالها.<br>&nbsp;<br></li>
        
        
                                   <li> نقدم تطبيقات مخصصة وفعالة من حيث الوقت والتكلفة تساعد المؤسسات على معالجة الفجوات الوظيفية وتحقيق أهداف العمل بفعالية.<br>&nbsp;<br></li>
                                    
                                    
                                 <li>   هدفنا هو توفير برامج عالية الجودة في جميع جوانب دورة التطوير من بداية الاتصال الأولي حتى مرحلة الدعم ما بعد التطوير.</li>   
                                </ul>
                                    <!-- <a routerLink="/about" class="read-more-btn">View More About us.</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rocket animate__animated animate__fadeInUp wow slow"><img src="assets/img/2030.png" alt="rocket"></div>
            <div class="vector"><img src="assets/img/vector-bg.png" alt="vector"></div>
            <div class="circle-pattern-2"></div>
        </section>
    </div>
</section>



