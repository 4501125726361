<div class="page-title animatedBackground">
    
    <div class="container">
        <h3>التوظيف</h3>
        <ul>
            <li><a router="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>التوظيف</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="features-area ptb-100">
    <div class="container">
     
        
        <div class="row  align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="analysis-text">
                    <h3>نحن نبحث عن عقول مبدعة للانضمام إلى فريقنا المتميز وجعله أفضل. هل أنت جاهز؟</h3>
                  
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="img mb-0">
                    <img  style="border-radius: 20px;   box-shadow: 5px 10px #f2f2f2;
                    " src="assets/img/career.png" alt="image">
                </div>
            </div>
        </div>
        
 </div>
</section>
<section class="analysis-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="analysis-form">
                    <h3>انضم الينا</h3>
                    <form enctype="multipart/form-data">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" placeholder="الاسم*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="البريد الإلكتروني*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}" placeholder="الهاتف*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="appliedfor" [ngModelOptions]="{standalone: true}" placeholder="التقديم لوظيفة*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="file" (change)="uploadFile($event.target.files)" [(ngModel)]="cv" [ngModelOptions]="{standalone: true}" placeholder="السيرة الذاتية*" class="form-control">
                        </div>
                        <button type="button" (click)="onSubmit()" class="btn btn-primary">التقديم</button>
                    </form>
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="analysis-text">
                 
                    <h3>:  لماذا الإنضمام الى OSIT ؟ </h3>
                    <h5> انضم إلينا لأننا نساعدك في بناء حياتك المهنية , إنها ليست مجرد وظيفة.<br>&nbsp;</h5>
                    <p>نوفر القوة لتقنيات العصر الحديث للشركات والأفراد في جميع أنحاء العالم. مع أفضل 1٪ من المواهب والمبتكرين والأشخاص الذين يتطلعون إلى المستقبل . تعد OSIT أول شركة تُعرف بموهبتها بقدر ما تشتهر بحلولها. جرب عامل الشعور بالسعادة والقيمة الحقيقية للتوازن بين العمل والحياة من خلال العمل معنا.</p>
              
                </div>
            </div>
        </div>
    </div>
</section>