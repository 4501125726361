<div class="page-title animatedBackground">
    <div class="container">
        <h3>الشروط و الأحكام</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>الشروط و الأحكام</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                   
                    <div class="blog-details-content">
                       <h4> شروط الإستخدام </h4>
                       <p><br></p>
<ul>
                        <li> سيتم اعتبار الشروط والأحكام التالية مقبولة من قبل المستخدم عند استخدام موقع www.osit.com.sa يُطلب منك قراءتها بعناية قبل استخدام خدمات هذا الموقع. </li>
                        
                        <li> يجب أن يشير مصطلح المستخدم إلى المستخدم الذي يتصفح الموقع. يجب أن يشير مصطلح OSIT إلى شركة البرامج المفتوحة لتقنية المعلومات. ويشير مصطلح "الموقع" إلى موقع www.osit.com.sa مملوك ومراقب من قبل OSIT. </li>
                        
                        <li> باستخدام الموقع فإنك توافق على اتباع الشروط والأحكام التالية والالتزام بها فيما يتعلق باستخدامك للموقع الإلكتروني. يجوز لـ OSIT مراجعة شروط الاستخدام في أي وقت دون إخطارك. قد تحتوي صفحات الموقع على شروط استخدام مختلفة منشورة. إذا كان هناك تعارض بين شروط الاستخدام وشروط الاستخدام المنشورة لصفحة أو قسم معين من الموقع  فيجب أن يكون لهذه الأخيرة الأسبقية فيما يتعلق باستخدامك لتلك الصفحة من الموقع. </li>
                        
                        <li> يجوز لـ OSIT إنهاء وصول المستخدم في أي وقت ولأي سبب. يجب أن تبقى الأحكام المتعلقة بإخلاء المسؤولية عن الضمان ودقة المعلومات والتعويض سارية بعد هذا الإنهاء. قد يراقب OSIT الوصول إلى الموقع. </li>
                        
                        <li> جميع المحتويات الموجودة على هذا الموقع هي ملكية حصرية لـ OSIT. البرامج والنصوص والصور والرسومات والفيديو والصوت المستخدم في هذا الموقع تنتمي إلى OSIT. لا يجوز نسخ أي مادة من هذا الموقع أو تعديلها أو إعادة إنتاجها أو إعادة نشرها أو تحميلها أو نقلها أو نشرها أو توزيعها بأي شكل من الأشكال دون إذن كتابي مسبق من OSIT. جميع الحقوق غير الممنوحة هنا محفوظة. الاستخدام غير المصرح به للمواد التي تظهر على هذا الموقع قد ينتهك حقوق الطبع والنشر والعلامات التجارية والقوانين الأخرى المعمول بها ، وقد يؤدي إلى عقوبات جنائية أو مدنية. OSIT هي علامة تجارية مسجلة لشركة OSIT. لا يجوز استخدام هذه العلامة التجارية بأي شكل من الأشكال دون موافقة خطية مسبقة من OSIT.</li>
                        
                        <li> لا تقدم OSIT أي ضمانات صريحا أو ضمنيا ، بما في ذلك على سبيل المثال لا الحصرتلك المتعلقة بالتسويق والملائمة لغرض معين  فيما يتعلق بأي معلومات أو بيانات أو بيانات أو منتجات متاحة على الموقع. </li>
                        
                        <li> يتم توفير الموقع وجميع المحتويات والمواد والمعلومات والبرامج والمنتجات والخدمات المقدمة على الموقع على أساس "كما هي" و "حسب توفرها". يتنصل OSIT صراحة من جميع الضمانات من أي نوع ، سواء كانت صريحة أو ضمنية  بما في ذلك  على سبيل المثال لا الحصر الضمانات الضمنية الخاصة بالتسويق والملائمة لغرض معين وعدم الانتهاك. </li>
                        
                        <li> لا تتحمل OSIT أي مسؤولية عن أي ضرر يلحق بنظام الكمبيوتر الخاص بالمستخدم أو فقدان البيانات الناتج عن تنزيل أي محتوى ومواد ومعلومات من الموقع. </li>
                        
                        <li> يجوز لـ OSIT تغيير أو إيقاف أي جانب من جوانب موقعه على الويب في أي وقت بما في ذلك محتواه أو ميزاته. تحتفظ OSIT بالحق في تغيير الشروط والأحكام المطبقة على استخدام الموقع. يجب أن تكون هذه التغييرات سارية المفعول فور إشعارها  والتي يجب وضعها على الموقع. </li>
                        
                        <li> لن يكون OSIT بأي حال من الأحوال مسؤولاً عن الأضرار من أي نوع ، بما في ذلك على سبيل المثال لا الحصرالأضرار المباشرة أو العرضية أو التبعية (بما في ذلك ، على سبيل المثال لا الحصرالأضرار الناجمة عن خسارة الأرباح ، وانقطاع الأعمال وفقدان البرامج أو المعلومات) الناشئة عن الاستخدام أو عدم القدرة على استخدام موقع الويب الخاص بـ OSIT ، أو أي معلومات مقدمة على موقع الويب ، أو في المنتجات ، أي مطالبة تؤدي إلى أخطاء أو سهو أو عدم دقة أخرى في المنتج أو تفسيرات لها. لا تسمح بعض الجهات القضائية بتحديد المسؤولية أو استبعادها. وفقًا لذلك ، قد لا تنطبق بعض القيود المذكورة أعلاه على المستخدم. </li>
                        
                        <li> يوافق المستخدم على تعويض والدفاع عن OSIT وحمايته من جميع الخسائر والنفقات والأضرار والتكاليف ، بما في ذلك أتعاب المحاماة المعقولة ، الناشئة عن أو المتعلقة بأي إساءة استخدام من قبل المستخدم للمحتوى والخدمات المقدمة على الموقع. </li>
                        
                        <li> تم الحصول على المعلومات الواردة في الموقع من مصادر يعتقد أنها موثوقة. يتنصل OSIT من جميع الضمانات المتعلقة بدقة أو اكتمال أو كفاية هذه المعلومات. </li>
                        
                        <li> إن حق المستخدم في الخصوصية له أهمية قصوى بالنسبة إلى OSIT. لن يتم مشاركة أي معلومات يقدمها المستخدم مع أي طرف ثالث. تحتفظ OSIT بالحق في استخدام المعلومات لتزويد المستخدم بتجربة أكثر تخصيصًا عبر الإنترنت. </li>
                        
                        <li> لا تقدم OSIT أي ضمانات على أن: (أ) الموقع سيلبي متطلباتك  (ب) سيكون الموقع متاحًا دون انقطاع أو في الوقت المناسب أو آمن أو خالي من الأخطاء ؛ (ج) النتائج التي يمكن الحصول عليها من استخدام الموقع أو أي خدمات مقدمة من خلاله ستكون دقيقة أو موثوقة. </li>
                   
                    </ul>
                    </div>
                </div>
            </div>

        
        </div>
    </div>
</section>

