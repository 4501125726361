import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-pos-solution',
  templateUrl: './pos-solution.component.html',
  styleUrls: ['./pos-solution.component.scss']
})
export class PosSolutionComponent implements OnInit {


  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'استكشف حلول نقاط البيع (POS) المتقدمة لدينا في أوسيت. نحن متخصصون في تقديم برمجيات وأنظمة POS عصرية لتعزيز أعمال التجزئة والمطاعم الخاصة بك في الرياض، المملكة العربية السعودية. اكتشف كيف يمكن أن تحسن حلولنا لنقاط البيع مبيعاتك، وإدارة المخزون، وتجربة العملاء.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
