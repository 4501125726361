<header class="top-header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5">
                <ul class="social-links">
                    <li>تابعنا على</li>
                    <li>
                        <a
                            href="https://www.facebook.com/osit.com.sa/"
                            target="_blank"
                            ><i class="icofont-facebook"></i
                        ></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/osit_sa" target="_blank"
                            ><i class="icofont-twitter"></i
                        ></a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/osit_sa/"
                            target="_blank"
                            ><i class="icofont-instagram"></i
                        ></a>
                    </li>
                    <li>
                        <a
                            href="https://www.linkedin.com/company/osit-sa/"
                            target="_blank"
                            ><i class="icofont-linkedin"></i
                        ></a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-7 col-md-7">
                <ul class="header-info">
                    <li><a href="https://support.osit.com.sa">الدعم</a></li>
                    <li><a routerLink="/career">التوظيف</a></li>
                    <li><a routerLink="/privacy-policy">سياسة الخصوصية</a></li>
                    <li>
                        <a routerLink="/quotation-request">احصل على عرض سعر</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
