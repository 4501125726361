import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-openbravo-solution',
  templateUrl: './openbravo-solution.component.html',
  styleUrls: ['./openbravo-solution.component.scss']
})
export class OpenbravoSolutionComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'استكشف حلول أوبنبرافو لدينا في أوسيت. نحن متخصصون في تنفيذ وتخصيص أنظمة أوبنبرافو ERP لتحسين عمليات أعمالك في الرياض، المملكة العربية السعودية. اكتشف كيف يمكن أن تسهم خبرتنا في أوبنبرافو في تبسيط عملياتك وتعزيز نمو أعمالك.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
