<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="newsletter">
            <h2>اشترك في صحيفتنا الاخبارية</h2>
            <form class="newsletter-form">
                <input type="email" [(ngModel)]="nemail" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="أدخل بريدك الإلكتروني" name="EMAIL">
                <button class="btn btn-primary"  (click)="onSubmitNL()" type="submit">اشترك</button>
            </form>
        </div>
    </div>
</section>