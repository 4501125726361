import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';  
import {HttpClient} from '@angular/common/http';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-blogscategory',
  templateUrl: './blogscategory.component.html',
  styleUrls: ['./blogscategory.component.scss']
})
export class BlogsCategoryComponent implements OnInit {

  blogs:any={category:{title:"Loading"},blogs:[{id:0,title:"Loading",content:"Loading",image:"1658236360aaa.png"}]};
  cats:any;
  latestblogs:any;
  constructor(private http : HttpClient,private route: ActivatedRoute){
    var id =  this.route.snapshot.paramMap.get('catId');
     this.http.get('https://admin.osit.com.sa/api/category/'+id)
    .subscribe(Response => {
  
      this.blogs=Response;
    });
  
     this.http.get('https://admin.osit.com.sa/api/categories')
    .subscribe(Response => {
  
     
      this.cats=Response;
    });
  
      this.http.get('https://admin.osit.com.sa/api/latestblogs')
    .subscribe(Response => {
  
     
      this.latestblogs=Response;
    });
}
 
async ngOnInit(): Promise<any> {
  
}

}
