import { Component, OnInit } from '@angular/core';
import { MoveDirection, ClickMode, HoverMode, OutMode } from "tsparticles-engine";
import {HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
import { Report } from 'notiflix/build/notiflix-report-aio';
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  name:any;
  email:any;
  appliedfor:any;
  cv:File = null;
  phone:any;
  cvfile:File=null;
  constructor(private http : HttpClient) { }

  ngOnInit() {
  }

  onSubmit() {
    if(this.name==null  || this.name==""){
      Report.failure(
          'نسيت الأسم',
          'يرجى ملئ حقل الاسم للإرسال!',
          'حسناً',
        );
    }
    else if(this.email==null || this.email==""){
      Report.failure(
        'نسيت البريد الإلكتروني',
        'يرجى ملئ حقل البريد الإلكتروني لإرسال الطلب!',
          'حسناً',
        );
    }
    else if(this.phone==null || this.phone==""){
      Report.failure(
          'نسيت الرقم',
          'يرجى ملئ حقل الرقم للإرسال!',
          'حسناً',
        );
    }
    else if(this.appliedfor==null || this.appliedfor==""){
      Report.failure(
        'نسيت التقديم لوظيفة',
        'يرجى ملئ حقل التقديم لوظيفة لإرسال الطلب!',
          'حسناً',
        );
    }
    else if(this.cv==null ){
      Report.failure(
        'نسيت رفع المستند',
        'يرجى رفع المستند لإرسال الطلب!',
          'حسناً',
        );
    }else{
      const myheader = new HttpHeaders();
      //myheader.set('Content-Type', 'multipart/form-data');

      let body = new FormData();
      body.append('Name', this.name);
      body.append('Phone', this.phone);
       body.append('Email', this.email);
      body.append('Applied For', this.appliedfor);
     
       body.append('Page', 'Career Page');
       body.append('cv', this.cvfile,this.cvfile.name); 

       let options = { headers: myheader };
        this.http.post("https://admin.osit.com.sa/api/contactus",body,options).subscribe((data) => {
        
          Report.success(
              'تم إرسال الطلب',
              'تم إرسال الطلب لنا و سيتم التواصل معك بأقرب وقت!',
              'حسناً',
            );
            this.name="";
            this.email="";
            this.appliedfor="";
            this.cv=null;
            this.phone="";
        });
     
    }
    
      }

      uploadFile(files: FileList) {  
     
          this.cvfile = files[0];
        
      }   

}
