<div class="page-title animatedBackground">
    <div class="container">
        <h3>Services</h3>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li class="dot-divider"></li>
            <li>Services</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Strategy & Research</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="icofont-chart-arrows-axis"></i>
                    </div>
                    <h3>Reports & Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="icofont-rocket-alt-2"></i>
                    </div>
                    <h3>Managment & Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="icofont-chart-pie"></i>
                    </div>
                    <h3>Infographics Content</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="icofont-location-arrow"></i>
                    </div>
                    <h3>Media Promotion</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="icofont-live-support"></i>
                    </div>
                    <h3>Quick Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>