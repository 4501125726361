import { Component, OnInit } from '@angular/core';
import {HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
import { Report } from 'notiflix/build/notiflix-report-aio';

@Component({
  selector: 'app-quotation-request',
  templateUrl: './quotation-request.component.html',
  styleUrls: ['./quotation-request.component.scss']
})
export class QuotationRequestComponent implements OnInit {
  name:any;
  email:any;
  message:any;
  phone:any;
  subject:any;
  company:any;
  service:string="";
  constructor(private http : HttpClient) { }

  ngOnInit() {
  }

  onSubmit() {
    if(this.name==null  || this.name==""){
      Report.failure(
          'نسيت الأسم',
          'يرجى ملئ حقل الاسم للإرسال!',
          'حسناً',
        );
    }
    else if(this.phone==null || this.phone==""){
      Report.failure(
          'نسيت الرقم',
          'يرجى ملئ حقل الرقم للإرسال!',
          'حسناً',
        );
    }
    else if(this.email==null || this.email==""){
      Report.failure(
          'نسيت البريد الإلكتروني',
          'يرجى ملئ حقل البريد الإلكتروني لإرسال الطلب!',
          'حسناً',
        );
    }
    else if(this.subject==null || this.subject==""){
      Report.failure(
        'نسيت الشركة',
        'يرجى ملئ حقل الشركة لإرسال الطلب!',
          'حسناً',
        );
    }
   
    else if(this.message==null || this.message==""){
      Report.failure(
        'نسيت الرسالة',
        'يرجى ملئ حقل الرسالة لإرسال الطلب!',
          'حسناً',
        );
    }else{
      const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      let body = new HttpParams();
      body = body.set('Name', this.name);
      body = body.set('Phone', this.phone);
      body = body.set('Email', this.email);
      

      
      //body = body.set('Company', this.company);
      body = body.set('Subject', this.subject);
     // body = body.set('Service', this.service);
      body = body.set('Message', this.message);
     
      body = body.set('Page', 'Request Quotation Page');
        this.http.post("https://admin.osit.com.sa/api/contactus", body).subscribe((data) => {
        
          Report.success(
              'تم إرسال الطلب',
              'تم إرسال الطلب لنا و سيتم التواصل معك بأقرب وقت!',
              'حسناً',
            );
            this.name="";
            this.email="";
            this.message="";
            this.phone="";
            this.subject="";
           // this.company="";
           // this.service="";
        });
     
    }
    
      }

}
