import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'تعرّف على شركتنا والتزامنا بتقديم خدمات تكنولوجيا المعلومات وحلولها من الدرجة الأولى في الرياض، المملكة العربية السعودية. اكتشف خبرة فريقنا واهتمامنا بالتميز التكنولوجي.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
