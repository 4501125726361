<div class="page-title animatedBackground">
    <div class="container">
        <h3>Services Details</h3>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li class="dot-divider"></li>
            <li>Services Details</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Strategy & Research</h3>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> Core Development</h4>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> Define Your Choices</h4>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                </div>
            </div>

            <div class="services-details-image">
                <img src="assets/img/services-details1.jpg" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/services-details2.jpg" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>Media Promotion</h3>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> Core Development</h4>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> Define Your Choices</h4>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>