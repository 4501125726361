<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner-four item-bg-1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/round_move.png" class="rotating"  alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>حلول تقنية استثنائية تعطي نتائج <span>حقيقية <h1 style="display:inline-block;">و</h1> مبتكرة</span></h1>
                                <p>نقدم استشارات تقنية لتصميم و تطويرالبرمجيات ومع توسع نطاق تطبيقات المواقع والهواتف المحمولة لجميع الأنظمة نقدم حلول برمجية مخصصة تناسب متطلباتك لترسم طريق نجاحاتك</p>
                                <a routerLink="/quotation-request" class="btn btn-primary">استشارتك المجانية</a>
                             <!--   <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner-four item-bg-2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/hero_itajer.png" alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content" style="position: relative;">
                                <h1><span>حلك الأمثل مع<br></span>آي تاجر<span> شريك </span>رواد الأعمال</h1>
                                <p>آي تاجر هو نظام ERP ينظّم عملك التجاري من دورة بيع و شراء و فوترة الكترونية مع مخزون و نظام موارد بشرية</p>
                                <a href="https://www.itajer.com" style="z-index: 10;" class="btn btn_itajer-primary">للمزيد عن آي تاجر</a>
                                <div>
                                <img src="assets/img/itajer.png" alt="" style="bottom: 0px;right: 200px; position: absolute; width: 350px; z-index: 0;">
                            </div>
                      <!--   <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner-four item-bg-2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/slide3.png" alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1><span>منتجك و فكرتك<br></span>بالتعاون<span> مع </span>خبراتنا</h1>
                                <p>نطبق رؤيتك باحترافية و نقدم خطط شاملة تنمي منتجاتك و تتناسب مع متطلباتك.</p>
                                <a routerLink="/quotation-request" class="btn btn-primary">اتصل بنا</a>
                      <!--   <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner-four item-bg-3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-image">
                                <img style="border-radius: 25px;" src="assets/img/slide_new_1.png"  alt="banner-img">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>طوّر <span>أعمالك </span><span>بالتحول الرقمي مع</span> Odoo</h1>
                                <p>ان التحول الرقمي للشركات والمنشئات يساعد على تطوير وتسهيل الأعمال اليومية بنظام برمجي متكامل لتخطيط موارد المؤسسات والشركات.</p>
                                <a routerLink="/quotation-request" class="btn btn-primary">طوّر أعمالك</a>
                                <!--   <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn">Play Now<i class="icofont-play-alt-3"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="services-area bg ptb-100">
    <div class="container">
        <div class="section-title">
         <!--   <span>We work with you not for you</span> -->
            <h3>التحول الرقمي للأعمال.</h3>
            <p>نقدم استشارات تقنية لتصميم و تطويرالبرمجيات ومع توسيع نطاق تطبيقات المواقع والجوال لجميع الأنظمة مع حلول برمجية مخصصة تناسب متطلباتك لتحقق طريق نجاحاتك!</p>
           
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-automation"></i>
                    </div>
                    <h3>Odoo للتطوير والدعم</h3>
                    <p>تطوير يشمل جميع الاعمال و الصناعات</p>
                    <a routerLink="/odoo-customization-and-support-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-dashboard-web"></i>
                    </div>
                    <h3>تطوير تطبيقات المواقع</h3>
                    <p>خدمات تطوير الويب الرائدة لتعزيز نمو أعمالك</p>
                    <a routerLink="/web-application-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-stock-mobile"></i>
                    </div>
                    <h3>تطوير تطبيقات الجوال</h3>
                    <p>تطوير تطبيقات الجوال  بشكل احترافي تناسب جميع الانظمة</p>
                    <a routerLink="/mobile-application-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-shopping-cart"></i>
                    </div>
                    <h3>تطوير التجارة الإلكترونية</h3>
                    <p>خدمات تطوير التجارة الإلكترونية الشاملة في أي إطار عمل</p>
                    <a routerLink="/ecommerce-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-code"></i>
                    </div>
                    <h3>تطوير حلول برمجية مخصصة</h3>
                    <p>تطوير مخصص لأي متطلب في أي إطار عمل</p>
                    <a routerLink="/custom-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-cloudapp"></i>
                    </div>
                    <h3>الخدمات السحابية والأمن السيبراني</h3>
                    <p>الخدمات السحابية والأمن السيبراني هي متطلب أساسي لكل مؤسسة</p>
                    <a routerLink="/cloud-server-and-cyber-securities-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-area ptb-100 bg-fbf9f8">
    <div class="container">
       

        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="row about-image">
                        <div class="col-6 col-lg-6 col-md-6">
                            <div class="image">
                                <img src="assets/img/home2.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-6 col-lg-6 col-md-6">
                            <div class="image">
                                <img src="assets/img/home1.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-6 col-lg-6 col-md-6">
                            <div class="image mt-30">
                                <img src="assets/img/home3.jpg" alt="about">
                            </div>
                        </div>
                        <div class="col-6 col-lg-6 col-md-6">
                            <div class="image mt-30">
                                <img src="assets/img/home4.jpg" alt="about">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="about-text">
                        <span>شراكة سعودية للابتكار التكنولوجي</span>
                        <h3>لماذا OSIT ؟</h3>
                        <ul style="font-style:italic">
                         <li>   نقوم بتحليل وتطوير وتنفيذ حلول الأعمال المتعلقة بالتقنيات التي تساعد الشركات العالمية على التركيز على أعمالها.<br>&nbsp;<br></li>


                           <li> نقدم تطبيقات مخصصة وفعالة من حيث الوقت والتكلفة تساعد المؤسسات على معالجة الفجوات الوظيفية وتحقيق أهداف العمل بفعالية.<br>&nbsp;<br></li>
                            
                            
                         <li>   هدفنا هو توفير برامج عالية الجودة في جميع جوانب دورة التطوير من بداية الاتصال الأولي حتى مرحلة الدعم ما بعد التطوير.</li>   
                        </ul>
                            <a routerLink="/about" class="read-more-btn">اقرأ اكثر عنا.</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rocket animate__animated animate__fadeInUp wow slow"><img src="assets/img/2030.png" alt="rocket"></div>
    <div class="vector"><img src="assets/img/vector-bg.png" alt="vector"></div>
    <div class="circle-pattern-2"></div>
</section>
<section class="working-process-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="section-title">
            <span>طريقة العمل</span>
            <h3>كيفية العمل لدينا</h3>
            <p>نحن نتطلع  باستخدام  استراتيجياتنا لنقدم لكل عميل أفضل الحلول التي تتناسب مع اعماله.</p>
            <!-- <a routerLink="/about" class="read-more-btn">Read More</a> -->
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-read-book"></i>
                    </div>
                    <h3>تخطيط</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-light-bulb"></i>
                    </div>
                    <h3>بحث</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-mathematical"></i>
                    </div>
                    <h3>تطوير</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-laptop-alt"></i>
                    </div>
                    <h3>اطلاق</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="analysis-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="analysis-form">
                    <h3>اطلب استشارة مجانية</h3>
                    <form>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" placeholder="الإسم*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}" placeholder="الرقم*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="البريد الإلكتروني*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="subject" [ngModelOptions]="{standalone: true}" placeholder="الموضوع*" class="form-control">
                        </div>
                        <div class="form-group">
                            <textarea type="text" [(ngModel)]="message" [ngModelOptions]="{standalone: true}" placeholder="الرسالة*" class="form-control"></textarea>
                        </div>
                        <button type="submit" (click)="onSubmit()" class="btn btn-primary">اطلب الآن</button>
                    </form>
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="analysis-text">
                    <h3>شريكك الموثوق في الرحلة الرقمية</h3>
                    <p>في عالم دائم التغيير نعمل لمساعدة الأعمال على توجيه مسارها إلى القمة. من خلال الجمع بين المزيج من الخبرات البشرية والقدرات التقنية  لنساعد في سرد قصة نجاحك وتحويلها إلى حقيقة.</p>
                    <ul >
                        <li><span style="font-size: 20px;"><b>O</b></span>ptimum - <b>الأفضل</b></li>
                        <li><span style="font-size: 20px;"><b>S</b></span>implicity - <b>البساطة</b></li>
                        <li><span style="font-size: 20px;"><b>I</b></span>ntegrity - <b>النزاهة</b></li>
                        <li><span style="font-size: 20px;"><b>T</b></span>rust and Respect - <b>التقدير والإحترام</b></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-users-alt-5"></i>
                    <p>العملاء النشطين:</p>
                    <h3><span class="odometer" data-count="200">00</span><span class="sign">+</span></h3>
                </div>
            </div>
            
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-rocket-alt-2"></i>
                    <p>نسبة النجاح:</p>
                    <h3><span class="odometer" data-count="91">00</span><span class="sign">%</span></h3>
                </div>
            </div>
            
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-laptop-alt"></i>
                    <p>المشاريع المنجزة:</p>
                    <h3><span class="odometer" data-count="550">00</span><span class="sign">+</span></h3>
                </div>
            </div>
            
            <div class="col-6 col-lg-3 col-sm-3 col-md-3">
                <div class="fun-fact">
                    <i class="icofont-award"></i>
                    <p>الجوائز:</p>
                    <h3><span class="odometer" data-count="14">00</span><span class="sign">+</span></h3>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="partner-area ptb-100  bg-fbf9f8">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/1.png" alt="partner"></a>
            </div>
        <!--<div class="partner-item">
                <a href="#"><img src="assets/img/client/2.png" alt="partner"></a>
            </div> -->
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/3.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/4.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/5.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/6.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/7.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/8.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/9.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/10.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/11.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/12.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/13.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/14.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/15.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/16.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/17.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/18.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/19.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/20.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/21.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/22.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/23.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/24.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a href="#"><img src="assets/img/client/25.png" alt="partner"></a>
            </div>
            
           
        </div>
    </div>
    <div class="vector"><img src="assets/img/vector-bg.png" alt="vector"></div>
</div>


<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>أخبارنا</span>
            <h3>آخر الأخبار</h3>
            <p>نحن نتطلع  باستخدام  استراتيجياتنا لنقدم لكل عميل أفضل الحلول التي تتناسب مع اعماله.</p>
            <a routerLink="/blog-1" class="read-more-btn">المزيد من الأخبار</a>
        </div>
        <div class="blog-slides owl-carousel owl-theme">
            
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>
