import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-hr-solution',
  templateUrl: './hr-solution.component.html',
  styleUrls: ['./hr-solution.component.scss']
})
export class HrSolutionComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'استكشف حلول إدارة الموارد البشرية الشاملة لدينا في أوسيت. نحن متخصصون في تقديم برمجيات وخدمات إدارة الموارد البشرية المصممة خصيصًا لتبسيط عمليات الموارد البشرية الخاصة بك في الرياض، المملكة العربية السعودية. اكتشف كيف يمكن أن تعزز حلولنا لإدارة الموارد البشرية إدارة القوى العاملة وكفاءة إدارة الموارد البشرية.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
