import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Report } from 'notiflix/build/notiflix-report-aio';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  nemail: any;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  onSubmitNL() {
    if (this.nemail == null || this.nemail == "") {
        Report.failure(
            'لقد نسيت البريد الإلكتروني ',
            'يرجى ملء حقل البريد الإلكتروني للاشتراك! ',
            'حسناً',
        );
    
    
    } else {
        const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        let body = new HttpParams();
        body = body.set('email', this.nemail);
        this.http.post("https://admin.osit.com.sa/api/newsletter", body).subscribe((data) => {

            Report.success(
                'تم إرسال الطلب',
                'الاشتراك في النشرة الإخبارية ناجح',
                'حسناً',
            );
           
            this.nemail = "";
            
        });

    }

}

}
