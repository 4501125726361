<div class="page-title animatedBackground">
    <div class="container">
        <h3>تطوير تطبيقات الهاتف المحمول</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/services">الخدمات</a></li>
            <li class="dot-divider"></li>
            <li>تطوير تطبيقات الهاتف المحمول</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>تطوير تطبيقات الهاتف المحمول لك خصيصاً ذات تجربة جديدة و خيالية</h3>
                <p>سواء كانت شركة ناشئة أو مؤسسة فإننا نساعد عملائنا على تحقيق أهدافهم من خلال الحفاظ على مشاريعهم في وضع التشغيل التلقائي منذ البداية. يعمل مطورونا بإتقان لتقديم منتجات رائعة وفريدة. </p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i>فكرة التخطيط الشبكي </h4>
                    <p>نحن نعتبر الأفكار الإبداعية بمثابة "جوهر" أي تطبيق ناجح. هذا هو السبب في أننا نركز على التخطيط الشبكي للتطبيق على أساس الوظائف التي تعكس فكرتك للتطبيق. من خلال نهج موجه نحو الفكرة . ونقف فخورين كواحدة من أفضل شركات تطوير التطبيقات.                    </p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تطوير الواجهات وتجربة المستخدم </h4>
                    <p>لا يوجد أفضل من تطبيق جميل سلس في الاستخدام و مريح للنظر. يتأكد المهندسين من خلال  تطوير الواجهات وتجربة المستخدم من أن تكون جميلة وعملية بقدر الإمكان. إلى جانب الوظائف التشغيلية  التي تطفي مزيجاً رائعأ بين سهولة الاستخدام و الجمال و الوظائف التشغيلية.                    </p>
                </div>
            </div>

            <div class="services-details-image">
                <img src="assets/img/mobile_app_new.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/mobile-app-development.png" alt="image">
            </div>

            <div class="services-details-desc">
               <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تحليل المتطلبات </h4>
                    <p>ماذا تريد من تطبيقك؟ هذا هو أول السؤال رئيسي الذي تحتاج إلى إجابته. يناقش خبراؤنا فكرتك معك ويقدمون لك تقرير الجدوى حول ما يمكننا أن نقدمه لك. تطوير تطبيق android و IOS مثالي من افكارك هو أفضل ما نقوم به.                    </p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> التخطيط الشبكي الاستراتيجي</h4>
                    <p>يعد وضع إطار شبكي لفكرتك أمرًا مهمًا وهذا أيضًا بالنسبة إلى تطبيق فريد وغير تقليدي يسمح لنا التخطيط الشبكي الاستراتيجي بدمج التصميم الممتاز والوظائف التشغيلية الصحيحة مع خطة كاملة تم إعدادها لجعله أحد أفضل تطبيقات Android و IOS .</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تخطيط تجربة المستخدم</h4>
                    <p>لا ننهي عملنا حتى ينجح تطبيقك Android و IOS من خلال عملية تجربة المستخدم لنحدث  ونزيل أي أخطاء في تطبيقك و نهدف إلى إنشاء تطبيقات Android و IOS تفيد وتؤثر على عملائك .                     </p>
                </div>
            </div>
        </div>
    </div>
</section>

