<div class="page-title animatedBackground">
    <div class="container">
        <h3>نظام إدارة الخدمات اللوجستية</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/our-solutions">الحلول</a></li>
            <li class="dot-divider"></li>
            <li>نظام إدارة الخدمات اللوجستية</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>نظام إدارة الخدمات اللوجستية</h3>
                <p> يعتبر نظام الخدمات اللوجستية من أهم القطاعات الحديثة في قطاع النقل التي تتناول احتياجات نقل البضائع وإدارة جميع تفاصيل الشحن مع تنفيذ حلول (LMS) و تبحث الشركات عن الإمكانيات التالية:</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> إدارة التسليم والجدولة</h4>
                    <p>يسمح ادارة التسليم و الجدولة ( TMS ) للعملاء بجدولة شحناتهم من خلال حساب نظام ادارة خدمات  اللوجستية (LMS) من خلال البوابة المخصصة لهم و يعتمد التسليم على معلومات  مهمة مثل الوقت المتوقع لتسليم الشحنات ونوع السيارة وسعتها وتحليلات حركة المرور التنبؤية وما إلى ذلك.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تتبع الطلب</h4>
                    <p>للحصول على رؤية كاملة لحركة المنتج يتم تزويد العملاء بإمكانية الوصول إلى بوابة خاصة عبر الإنترنت تراقب الشحن على الطريق وتنبيه إلى أي استثناءات للنقل أو تأخيرات غير متوقعة.</p>
                </div>
            </div>

            <div class="services-details-image">
                <img src="assets/img/cms.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/logistics-solution-4.jpg" style="border-radius:25px ;" alt="image">
            </div>

            <div class="services-details-desc">
               <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> المحاسبة في الخدمات اللوجستية</h4>
                    <p>يسمح نظام الخدمات اللوجستية العملاء على اطلاع بنفقات الشحن الخاصة بهم حيث يحسب جميع المستندات المتعلقة بالشحن في مكان واحد.حيث  يبسط النظام الإجراءات المالية عن طريق تخصيص التكاليف  وانشاء ارقام للفواتيرلأعمال الميزانية والتقاريروإنشاء ودفع فواتير الشحن إلخ.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> الخدمات اللوجستية العكسية</h4>
                    <p>تعد إدارة العائدات من المستهلك إلى المنتج عملية صعبة من الناحية اللوجستية. لذلك يجدر تطبيق نظام لوجستي عكسي (RLS) يساعد في تبسيط عمليات التعديل والإرجاع وإعادة تخصيص المنتج.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تتبع المعلومات</h4>
                    <p>عملية التتبع عند الإرجاع  يجب تتبع المعلومات حول موقع المنتج وسبب الإرجاع. يمكن أن تساعد الخدمات اللوجيستية العكسية (RLS) في إدارة هذه المعلومات حتى تعرف المنتجات التي تم إرجاعها  وسبب الإرجاع  وما إذا تم شحنها مرة أخرى إلى العميل.</p>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> حالة الإرجاع</h4>
                    <p>من خلال منح العملاء مشاهدة مرتجعاتهم  ستزيد من رضاهم مما يؤدي بالإحتفاظ بالعملاء وطريقة جيدة للقيام بذلك هي تقديم ملاحظات فورية حول عمليات الإرجاع لطلباتهم في بوابة المرتجعات أو باستخدام إشعارات البريد الإلكتروني أو الرسائل القصيرة.</p>
                </div>
            </div>
        </div>
    </div>
</section>

