<div class="page-title animatedBackground">
    <div class="container">
        <h3>استشارة مجانية</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>استشارة مجانية</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>
<section class="analysis-area ptb-100 bg-fbf9f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="analysis-form">
                    <h3>اطلب استشارة مجانية</h3>
                    <form>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" placeholder="الإسم*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}" placeholder="الرقم*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="البريد الإلكتروني*" class="form-control">
                        </div>
                        <div class="form-group">
                            <input type="text"  [(ngModel)]="subject" [ngModelOptions]="{standalone: true}" placeholder="الموضوع*" class="form-control">
                        </div>
                        <div class="form-group">
                            <textarea type="text" [(ngModel)]="message" [ngModelOptions]="{standalone: true}" placeholder="الرسالة*" class="form-control"></textarea>
                        </div>
                        <button type="submit" (click)="onSubmit()" class="btn btn-primary">اطلب الآن</button>
                    </form>
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="analysis-text">
                    <h3>شريكك الموثوق في الرحلة الرقمية</h3>
                    <p>في عالم دائم التغيير نعمل لمساعدة الأعمال على توجيه مسارها إلى القمة. من خلال الجمع بين المزيج من الخبرات البشرية والقدرات التقنية  لنساعد في سرد قصة نجاحك وتحويلها إلى حقيقة.</p>
                    <ul >
                        <li><span style="font-size: 20px;"><b>O</b></span>ptimum - <b>الأفضل</b></li>
                        <li><span style="font-size: 20px;"><b>S</b></span>implicity - <b>البساطة</b></li>
                        <li><span style="font-size: 20px;"><b>I</b></span>ntegrity - <b>النزاهة</b></li>
                        <li><span style="font-size: 20px;"><b>T</b></span>rust and Respect - <b>التقدير والإحترام</b></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>