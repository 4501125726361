import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogs:any=[{category:{title:"Loading"},blog:{id:0,title:"Loading",content:"Loading",image:"1658236360aaa.png"}}];
  cats:any;
  latestblogs:any
  constructor(private http : HttpClient){
     
}
 

  ngOnInit(): void {
    this.http.get('https://admin.osit.com.sa/api/blogs')
    .subscribe(Response => {
 
      this.blogs=Response;
    });

    this.http.get('https://admin.osit.com.sa/api/categories')
    .subscribe(Response => {
 
     
      this.cats=Response;
    });

    this.http.get('https://admin.osit.com.sa/api/latestblogs')
    .subscribe(Response => {
 
     
      this.latestblogs=Response;
    });
 
  }

}
