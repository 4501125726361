import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'استكشف حلول تكنولوجيا المعلومات الشاملة لدينا في أوسيت. من الاستشارات التقنية إلى تطوير البرمجيات وحلول الشبكات، نقدم مجموعة واسعة من خدمات التكنولوجيا مصممة خصيصًا لتلبية احتياجات أعمالك في الرياض، المملكة العربية السعودية. اكتشف كيف يمكن خبرتنا أن تمنح منظمتك النجاح والتقدم.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
