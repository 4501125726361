import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { TeamComponent } from './components/pages/team/team.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';

import { ErpSolutionComponent } from './components/pages/erp-solution/erp-solution.component';
import { OdooSolutionComponent } from './components/pages/odoo-solution/odoo-solution.component';
import { OpenbravoSolutionComponent } from './components/pages/openbravo-solution/openbravo-solution.component';
import { HrSolutionComponent } from './components/pages/hr-solution/hr-solution.component';
import { PosSolutionComponent } from './components/pages/pos-solution/pos-solution.component';
import { HospitalSolutionComponent } from './components/pages/hospital-solution/hospital-solution.component';
import { LogisticsSoloutionComponent } from './components/pages/logistics-solutions/logistics-solutions.component';
import { OdooCustomizationComponent } from './components/pages/odoo-customization-support-service/odoo-customization-support-service.component';
import { WebApplicationComponent } from './components/pages/web-application-development-service/web-application-development-service.component';
import { MobileApplicationComponent } from './components/pages/mobile-application-development-service/mobile-application-development-service.component';
import { EcommerceDevelopmentComponent } from './components/pages/ecommerce-development-service/ecommerce-development-service.component';
import { CustomDevelopmentComponent } from './components/pages/custom-development-service/custom-development-service.component';
import { CloudServerComponent } from './components/pages/cloud-server-cyber-securities-service/cloud-server-cyber-securities-service.component';

import { ServiceComponent } from './components/pages/services/services.component';
import { SolutionComponent } from './components/pages/solutions/solutions.component';

import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { CareerComponent } from './components/pages/career/career.component';
import { QuotationRequestComponent } from './components/pages/quotation-request/quotation-request.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { BlogsCategoryComponent } from './components/pages/blogsCategory/blogscategory.component';




const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'about', component: AboutComponent },
    { path: 'our-solutions', component: SolutionComponent },
    { path: 'erp-solution', component: ErpSolutionComponent },
    { path: 'odoo-solution', component: OdooSolutionComponent },
    { path: 'openbravo-solution', component: OpenbravoSolutionComponent },
    { path: 'hr-solution', component: HrSolutionComponent },
    { path: 'pos-solution', component: PosSolutionComponent },
    { path: 'hospital-solution', component: HospitalSolutionComponent },
    { path: 'logistics-solution', component: LogisticsSoloutionComponent },
    { path: 'services', component: ServiceComponent },
    { path: 'odoo-customization-and-support-service', component: OdooCustomizationComponent },
    { path: 'web-application-development-service', component: WebApplicationComponent },
    { path: 'mobile-application-development-service', component: MobileApplicationComponent },
    { path: 'ecommerce-development-service', component: EcommerceDevelopmentComponent },
    { path: 'custom-development-service', component: CustomDevelopmentComponent },
    { path: 'cloud-server-and-cyber-securities-service', component: CloudServerComponent },
    { path: 'blogs', component: BlogsComponent },
    { path: 'blogsbycategory/:catId', component: BlogsCategoryComponent },
    { path: 'blog-details/:blogId', component: BlogDetailsComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'quotation-request', component: QuotationRequestComponent },
    { path: 'career', component: CareerComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-condition', component: TermsConditionComponent },

    { path: '**', component: ErrorComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
