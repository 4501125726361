import { Component, OnInit } from '@angular/core';
import { Report } from 'notiflix/build/notiflix-report-aio';
import {HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
name:any;
email:any;
subject:any;
message:any;
  constructor(private http : HttpClient) { }

  ngOnInit() {
  }
  onSubmit() {
if(this.name==null  || this.name==""){
  Report.failure(
    'نسيت الأسم',
    'يرجى ملئ حقل الاسم للإرسال!',
    'حسناً',
  );
}else if(this.email==null || this.email==""){
  Report.failure(
    'نسيت البريد الإلكتروني',
    'يرجى ملئ حقل البريد الإلكتروني لإرسال الطلب!',
    'حسناً',
  );
}
else if(this.subject==null || this.subject==""){
  Report.failure(
    'نسيت الشركة',
    'يرجى ملئ حقل الشركة لإرسال الطلب!',
      'حسناً',
    );
}else{
  const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  let body = new HttpParams();
  body = body.set('Name', this.name);
  body = body.set('Email', this.email);
  body = body.set('Subject', this.subject);
  body = body.set('Message', this.message);
  body = body.set('Page', 'Contact US');
    this.http.post("https://admin.osit.com.sa/api/contactus", body).subscribe((data) => {
    
      Report.success(
        'تم إرسال الطلب',
        'تم إرسال الطلب لنا و سيتم التواصل معك بأقرب وقت!',
        'حسناً',
      );
        this.name="";
        this.email="";
        this.subject="";
        this.message="";
    });
 
}

  }
}

