<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    style="background-color: #ffffff !important"
>
    <div class="container">
        <a class="navbar-brand" routerLink="/"
            ><img style="width: 120px" src="assets/img/logo.png" alt="image"
        /></a>

        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li
                    class="nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >الصفحة الرئيسية</a
                    >
                </li>
                <li
                    class="nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/about"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >من نحن</a
                    >
                </li>
                <li class="nav-item">
                    <a routerLink="/our-solutions" class="nav-link"
                        >الحلول <i class="icofont-simple-down"></i
                    ></a>
                    <ul class="dropdown-menu">
                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/odoo-solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Odoo ERP</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/openbravo-solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Openbravo ERP</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/hr-solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >نظام إدارة الموارد البشرية HR</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/pos-solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >نقاط البيع (POS)</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/hospital-solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >نظام إدارة المستشفيات</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/logistics-solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >نظام إدارة الخدمات اللوجستية</a
                            >
                        </li>
                    </ul>
                </li>

                <li class="nav-item">
                    <a routerLink="/services" class="nav-link"
                        >خدماتنا <i class="icofont-simple-down"></i
                    ></a>
                    <ul class="dropdown-menu">
                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/odoo-customization-and-support-service"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >تطوير اودو الدعم الفني</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/web-application-development-service"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >تطوير تطبيقات الويب</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/mobile-application-development-service"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >تطوير تطبيقات الهاتف المحمول</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/ecommerce-development-service"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >تطوير التجارة الإلكترونية</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/custom-development-service"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >تطوير حلول برمجية مخصصة</a
                            >
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/cloud-server-and-cyber-securities-service"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >الخدمات السحابية والأمن السيبراني</a
                            >
                        </li>
                    </ul>
                </li>

                <li
                    class="nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/blogs"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >المدونة</a
                    >
                </li>

                <li
                    class="nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/contact"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >تواصل معنا</a
                    >
                </li>
            </ul>

            <div class="others-option">
                      <div class="lang-switcher">
                    <select (change)="onChangeLang($event.target.value)">
                        
                        <option>Ara</option>
                        <option>Eng</option>
                    </select>
                </div> 

                <div class="call-us">
                    <span> اتصل بنا:</span>
                    <a href="tel:920006748">920006748</a>
                </div>

                <div class="modal-taggle-button">
                    <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#sidebarModal"
                        ><span></span
                    ></a>
                </div>
            </div>
        </div>
    </div>
</nav>

<div class="modal left fade sidebarModal" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/"
                        ><img src="assets/img/logo.png" alt="logo"
                    /></a>
                </div>
                <p>
                    Open Software Information Technology (OSIT) تجمع شركة
                    البرامج المفتوحة لتقنية المعلومات (OSIT) مجموعة من العقول
                    المتحمسة لتحويل رؤيتك إلى واقع يمنح المستخدمين تجربة رقمية
                    رائعة. بدءًا من تصميم مواقع الويب والتطوير وتطبيقات الأجهزة
                    المحمولة القابلة للتطويروتنفيذ تخطيط موارد المؤسسات (ERP)
                    ووصولا الى منصات SaaS والحلول الذي نقوم بتنفيذها يسرع
                    الكفاءة ويحفز النمو لعملائنا.
                </p>
                <ul class="modal-contact-info">
                    <li>
                        <i class="icofont-google-map"></i>
                        <strong>7843 طريق الدوار الشرقي,</strong> الريان الرياض
                        14211-2294
                    </li>
                    <li>
                        <i class="icofont-ui-call"></i>
                        <strong>920006748</strong> اتصل بنا
                    </li>
                    <li>
                        <i class="icofont-envelope"></i>
                        <strong> support@osit.com.sa </strong>  خدمة مستمرة 24/7
                    </li>
                </ul>
                <ul class="social-links">
                    <li>
                        <a href="https://www.facebook.com/osit.com.sa/"
                            ><i class="icofont-facebook"></i
                        ></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/osit_sa"
                            ><i class="icofont-twitter"></i
                        ></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/osit_sa/"
                            ><i class="icofont-instagram"></i
                        ></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/osit-sa/"
                            ><i class="icofont-linkedin"></i
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
