<div class="page-title animatedBackground">
    <div class="container">
        <h3>الحلول</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>الحلول</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-automation"></i>
                    </div>
                    <h3>Odoo ERP</h3>
                    <p>المنصة الوحيدة التي ستحتاجها للمساعدة في إدارة أعمالك: تطبيقات متكاملة وبسيطة ومحبوبة من قبل ملايين المستخدمين لنظام Odoo.</p>
                    <a routerLink="/odoo-solution" class="read-more-btn">اقرأ أكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-computer"></i>
                    </div>
                    <h3>Openbravo ERP</h3>
                    <p>من صيانة البيانات الرئيسية إلى تخطيط البضائع وتوزيعها ، يسمح Openbravo بتقديم المنتجات بشكل أسرع وأكثر دقة بالإضافة إلى عمليات التخصيص والتجديد بفعالية اكبر.</p>
                    <a routerLink="/openbravo-solution" class="read-more-btn">اقرأ أكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-people"></i>
                    </div>
                    <h3>إدارة الموارد البشرية HR</h3>
                    <p>يوفر نظام إدارة الموارد البشرية المصمم حسب الطلب من OSIT مجموعة من الخدمات والامكانيات التي تجعل تقديم الخدمات أسهل وأكثر كفاءة للجميع باستخدام أداة واحدة لإدارة الموارد البشرية.</p>
                    <a routerLink="/hr-solution" class="read-more-btn">اقرأ أكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-bar-code"></i>
                    </div>
                    <h3>نقاط البيع (POS)</h3>
                    <p>يعتبر نظام نقاط البيع (POS) أحد الحلول الذي يتميز بأكثر فاعلية ومتاح لجميع الأجهزة المحمولة وأجهزة الكمبيوتر وما الى ذلك لتقديم خدمة عملاء ممتازة في كل مكان في المتجر. </p>
                    <a routerLink="/pos-solution" class="read-more-btn">اقرأ أكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-stethoscope-alt"></i>
                    </div>
                    <h3>نظام إدارة المستشفيات</h3>
                    <p>هو برنامج نظام إدارة مستشفيات مصمم لإدارة جميع عمليات المستشفى من جميع الجوانب الإدارية والطبية والمالية والهدف الرئيسي هو تنظيم العمل في المستشفى. </p>
                    <a routerLink="/hospital-solution" class="read-more-btn">اقرأ أكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-vehicle-delivery-van"></i>
                    </div>
                    <h3>نظام إدارة الخدمات اللوجستية</h3>
                    <p>يعتبر نظام الخدمات اللوجستية من أهم القطاعات الحديثة في قطاع النقل التي تتناول احتياجات نقل البضائع وإدارة جميع تفاصيل الشحن مع تنفيذ حلول (LMS) و تبحث الشركات عن الإمكانيات التالية. </p>
                    <a routerLink="/logistics-solution" class="read-more-btn">اقرأ أكثر</a>
                </div>
            </div>
        </div>
    </div>
</section>

