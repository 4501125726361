<div class="page-title animatedBackground">
    <div class="container">
        <h3>Odoo</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/our-solutions">حلولنا</a></li>
            <li class="dot-divider"></li>
            <li>Odoo</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>التطبيق المؤسسي الوحيد الذي يملك قابلية للتطوير </h3>
                <p>المنصة الوحيدة التي ستحتاجها للمساعدة في إدارة أعمالك: تطبيقات متكاملة وبسيطة ومحبوبة من قبل ملايين المستخدمين لنظام Odoo. </p>
                <div class="features-text">
                    <h4> مقترحات قيمة ومميزة كل ما تحتاجه مع تجربة مستخدم من الدرجة الأولى.</h4>
                    <p>تعمل مجموعة التطبيقات لدينا معًا بسلاسة تامة مما يمنحك القدرة على مواجهة و اتمام اعمالك بشكل مريح ويمكن الوصول إليه من أي مكان باستخدام أي جهاز.</p>
                    <p>يسمح لنا نموذج التطوير مفتوح المصدر لـ Odoo بالاستفادة من آلاف المطورين وخبراء الأعمال لبناء أكبر نظام في العالم لتطبيقات الأعمال المتكاملة بالكامل.</p>
                </div>
              
            </div>

            <div class="services-details-image">
                <img src="assets/img/odoo.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/odoo2.png" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>أهم التطبيقات</h3>
        
                <div class="features-text">
                    <div class="row">
                        <div class="col-md-6">
                            <h4><i class="icofont-check-alt"></i> المبيعات</h4>
                            <h4><i class="icofont-check-alt"></i> المشتريات</h4>
                            <h4><i class="icofont-check-alt"></i> المحاسبة</h4>
                            <h4><i class="icofont-check-alt"></i> الفواتير</h4>
                            <h4><i class="icofont-check-alt"></i> المخازن</h4>
                            <h4><i class="icofont-check-alt"></i> ادارة علاقات العملاء</h4>
                            <h4><i class="icofont-check-alt"></i> العقود</h4>
                            <h4><i class="icofont-check-alt"></i> التصنيع</h4>
                            <h4><i class="icofont-check-alt"></i> تخطيط الاحتياجات المادية</h4>
                        </div>
                        <div class="col-md-6">

                            <h4><i class="icofont-check-alt"></i> الصيانة</h4>
                            <h4><i class="icofont-check-alt"></i> الموظفين</h4>
                            <h4><i class="icofont-check-alt"></i> الرواتب</h4>
                            <h4><i class="icofont-check-alt"></i> الحضور</h4>
                            <h4><i class="icofont-check-alt"></i> الأوقات</h4>
                            <h4><i class="icofont-check-alt"></i> العهد</h4>
                            <h4><i class="icofont-check-alt"></i> ادارة التطلبات</h4>
                            <h4><i class="icofont-check-alt"></i> المشاريع</h4>
                            <h4><i class="icofont-check-alt"></i> ادارة الأوقات</h4>
                        </div>
                       </div>
                
                  
                    
                </div>
            </div>
        </div>
    </div>
</section>

