<div class="page-title animatedBackground">
    <div class="container">
        <h3>الأخبار</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>الأخبار</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    
                    <div class="col-lg-6 col-md-6" *ngFor="let e of blogs;">
                        <div class="single-blog-post">
                            <a routerLink="/blog-details/{{ e.blog.id }}" class="blog-image"><img style="border-radius: 25px;" src="http://admin.osit.com.sa/uploads/blogimages/{{ e.blog.image }}" alt="blog"></a>
                            <div class="blog-post-content">
                                <span>{{ e.category.title }}</span>
                                <h3><a routerLink="/blog-details/{{ e.blog.id }}">{{ e.blog.title }}</a></h3>
                                <p>{{ e.blog.content }}</p>
                                <a routerLink="/blog-details/{{ e.blog.id }}" class="read-more-btn">اقرأ أكثر</a>
                            </div> 
                        </div>
                    </div>
                     
               <!--    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <nav aria-label="navigation">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-left"></i></a></li>
                                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#"><i class="icofont-double-right"></i></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="side-bar mb-0">
                    
                    <div class="widget-box categories-box">
                        <h3 class="title">الفئات</h3>
                        <ul >
                            <li *ngFor="let ee of cats;"><a href="blogsbycategory/{{ ee.id }}"><i class="icofont-dotted-right"></i> {{ ee.title }} ({{ ee.blogs }})</a></li>
                        </ul>
                    </div>

                    <div class="widget-box recent-post-box mb-0">
                        <h3 class="title">آخر المنشورات</h3>
                        <ul >
                            <li *ngFor="let lblog of latestblogs;">
                                <div class="recent-post-thumb">
                                    <a href="blog-details/{{ lblog.blog.id }}"><img src="http://admin.osit.com.sa/uploads/blogimages/{{ lblog.blog.image }}" alt="pic"></a>
                                </div>
                                <div class="recent-post-desc">
                                    <h3><a href="blog-details/{{ lblog.blog.id }}">{{ lblog.blog.title }}</a></h3>
                                    <span class="date">{{lblog.blog.created_at | date: 'dd MMMM yyyy'}}</span>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>

