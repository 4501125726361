<div class="page-title animatedBackground">
    <div class="container">
        <h3>نقاط البيع (POS)</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/our-solutions">الحلول</a></li>
            <li class="dot-divider"></li>
            <li>نقاط البيع (POS)</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>طور خدمة العملاء باستخدام نقاط البيع</h3>
                <p>يعتبر نظام نقاط البيع (POS) أحد الحلول الذي يتميز بأكثر فاعلية  ومتاح لجميع الأجهزة المحمولة وأجهزة الكمبيوتر وما الى ذلك لتقديم خدمة عملاء ممتازة في كل مكان في المتجر.</p>
              
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> اعتماد نظام POS حديث ومتكامل الميزات يمكن تشغيله على أي جهاز باستخدام متصفح الويب فقط</h4>
                    <h4><i class="icofont-check-alt"></i> القدرة على استخدام نظام نقاط البيع من خلال أي نوع من الأجهزة</h4>
                    <h4><i class="icofont-check-alt"></i> احصل على حرية اختيار الأجهزة بفضل واجهة المستخدم سريعة الاستجابة والحد الأدنى من المتطلبات الفنية التي تدعمها مجموعة واسعة من الأجهزة.</h4>
                    <h4><i class="icofont-check-alt"></i> اضمن مبيعات متواصلة باستخدام تقنية Openbravo التي تعمل بكلتا الحالتين متصل وغير متصل بالإنترنت .</h4>
         

                </div>
            </div>

            <div class="services-details-image">
                <img src="assets/img/pos.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/pos2.png" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>المميزات</h3>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> إدارة نقاط البيع متعددة المتاجر</h4>
                    <h4><i class="icofont-check-alt"></i> ادارة المبيعات  ويعمل في الحالتين متصل وغير متصل</h4>
                    <h4><i class="icofont-check-alt"></i> إدارة الطلبات</h4>
                    <h4><i class="icofont-check-alt"></i> ادارة العملاء</h4>
                    <h4><i class="icofont-check-alt"></i> العروض الترويجية</h4>
                    <h4><i class="icofont-check-alt"></i> عمليات الدفع</h4>
                    <h4><i class="icofont-check-alt"></i> اقفال اليومي</h4>
                    <h4><i class="icofont-check-alt"></i> إدارة المنتجات والمخزون</h4>
                </div>
              
            </div>
        </div>
    </div>
</section>