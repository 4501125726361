<div class="page-title animatedBackground">
    <div class="container">
        <h3>Project</h3>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li class="dot-divider"></li>
            <li>Project</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="project-area ptb-100">
    <div class="container">
        <div class="shorting-menu text-center">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".brand">Brand</button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".graphic">Graphic</button>
            <button class="filter" data-filter=".photoshop">Photoshop</button>
            <button class="filter" data-filter=".illustrator">Illustrator</button>
        </div>
        <div class="shorting">
            <div class="row m-0">
                <div class="col-lg-6 col-md-6 col-sm-6 mix brand p-0">
                    <div class="single-work">
                        <img src="assets/img/work-img1.jpg" alt="work-img">
                        <div class="work-content">
                            <h3>Bubble Wrap</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
                        <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-6 mix design p-0">
                    <div class="single-work">
                        <img src="assets/img/work-img2.jpg" alt="work-img">
                        <div class="work-content">
                            <h3>Fluffernutter</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
                        <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-6 mix graphic p-0">
                    <div class="single-work">
                        <img src="assets/img/work-img3.jpg" alt="work-img">
                        <div class="work-content">
                            <h3>Crock-Pot</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
                        <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-6 mix photoshop p-0">
                    <div class="single-work">
                        <img src="assets/img/work-img4.jpg" alt="work-img">
                        <div class="work-content">
                            <h3>Chapstick </h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
                        <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-6 mix design p-0">
                    <div class="single-work">
                        <img src="assets/img/work-img5.jpg" alt="work-img">
                        <div class="work-content">
                            <h3>Kleenex</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
                        <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-6 mix illustrator p-0">
                    <div class="single-work">
                        <img src="assets/img/work-img6.jpg" alt="work-img">
                        <div class="work-content">
                            <h3>Ping-Pong</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        <a routerLink="/projects-details" class="link-btn"><i class="icofont-link"></i></a>
                        <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>