<div class="page-title animatedBackground">
    <div class="container">
        <h3>نظام الموارد البشرية </h3>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/our-solutions">الحلول</a></li>
            <li class="dot-divider"></li>
            <li>نظام الموارد البشرية</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>نظام إدارة الموارد البشرية حسب قانون العمل السعودي.</h3>
                <p>يوفر نظام إدارة الموارد البشرية المصمم حسب الطلب من OSIT مجموعة من الخدمات والامكانيات  التي تجعل تقديم الخدمات أسهل وأكثر كفاءة للجميع باستخدام أداة واحدة لإدارة الموارد البشرية والتي تتميز وادارة الرواتب ومعلومات الموظفين  والخدمة الذاتية وادارة الاجازات والسلف وتقييم الأداء وغيرها ليتم  خلق تجربة قوة عاملة أكثر جاذبية واتخاذ قرارات دقيقة  لموائمة أداء القوى العاملة بإحكام مع أداء الأعمال .</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> تواجد بيانات الموارد البشرية والبيانات المالية في مكان واحد</h4>
                    <h4><i class="icofont-check-alt"></i> تقليل الجهد والتكلفة الإدارية للموارد البشرية.</h4>
                    <h4><i class="icofont-check-alt"></i> تواصل بقرب مع موظفك.</h4>
                    <h4><i class="icofont-check-alt"></i> تحسين تقديم خدمات الموارد البشرية.</h4>
                    <h4><i class="icofont-check-alt"></i> زيادة أداء القوى العاملة.</h4>
                </div>
              
            </div>

            <div class="services-details-image">
                <img src="assets/img/hr-new.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/hr-management-system.jpg" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>المميزات</h3>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i>إدارة شؤون الموظفين</h4>
                    <h4><i class="icofont-check-alt"></i>الخدمة الذاتية</h4>
                    <h4><i class="icofont-check-alt"></i>ادارة الحضور </h4>
                    <h4><i class="icofont-check-alt"></i>ادارة الرواتب</h4>
                    <h4><i class="icofont-check-alt"></i>ادارة الإجازات</h4>
                    <h4><i class="icofont-check-alt"></i>ادارة السلف</h4>
                    <h4><i class="icofont-check-alt"></i>ادارة العقود</h4>
                    <h4><i class="icofont-check-alt"></i>ادارة التوظيف</h4>
                    <h4><i class="icofont-check-alt"></i>أداء الموظفين</h4>
                    <h4><i class="icofont-check-alt"></i>ادارة الطلبات</h4>
                    <h4><i class="icofont-check-alt"></i>إدارة الخطابات</h4>
                    
                </div>
                
            </div>
        </div>
    </div>
</section>

