import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-odoo-customization-support-service',
  templateUrl: './odoo-customization-support-service.component.html',
  styleUrls: ['./odoo-customization-support-service.component.scss']
})
export class OdooCustomizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
