import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-application-development-service',
  templateUrl: './mobile-application-development-service.component.html',
  styleUrls: ['./mobile-application-development-service.component.scss']
})
export class MobileApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
