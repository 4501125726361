import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hospital-solution',
  templateUrl: './hospital-solution.component.html',
  styleUrls: ['./hospital-solution.component.scss']
})
export class HospitalSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
