import { Component, OnInit } from '@angular/core';
import { MoveDirection, ClickMode, HoverMode, OutMode } from "tsparticles-engine";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Report } from 'notiflix/build/notiflix-report-aio';
declare function slideblog(): any;
declare var jQuery: any;
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})

export class HomeOneComponent implements OnInit {
    name: any;
    email: any;
    subject: any;
    message: any;
    phone: any;


    latestblogs: any;
    constructor(private http: HttpClient,private meta: Meta) {
        this.meta.removeTag('name=keywords');
        this.meta.removeTag('name=description');
        this.meta.addTags([ 
          { name: 'description', content: 'مزود رائد لخدمات تكنولوجيا المعلومات وحلولها في الرياض، المملكة العربية السعودية. نحن نقدم استشارات تقنية متخصصة، وتطوير برمجي، وحلول الشبكات، والمزيد للشركات في الرياض. اتصل بنا للحصول على حلول تكنولوجيا متطورة.' }, 
          { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
      ]);

    }

    ngOnInit() {
        this.http.get('https://admin.osit.com.sa/api/latestblogs')
            .subscribe(Response => {


                this.latestblogs = Response;

                //  slideblog();

                (function ($) {
                    $(document).ready(function () {
                        $('.blog-slides').owlCarousel({
                            loop: true,
                            autoplay: true,
                            nav: false,
                            mouseDrag: true,
                            autoplayHoverPause: true,
                            responsiveClass: true,
                            dots: true,
                            margin: 30,
                            navText: [
                                "<i class='icofont-double-left'></i>",
                                "<i class='icofont-double-right'></i>"
                            ],
                            responsive: {
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },
                                1200: {
                                    items: 3,
                                }
                            }
                        });
                    });
                })(jQuery);
            });
    }


    onSubmit() {
        if (this.name == null || this.name == "") {
            Report.failure(
                'نسيت الأسم',
                'يرجى ملئ حقل الاسم للإرسال!',
                'حسناً',
            );
        }
        else if (this.phone == null || this.phone == "") {
            Report.failure(
                'نسيت الرقم',
                'يرجى ملئ حقل الرقم للإرسال!',
                'حسناً',
            );
        } else {
            const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            let body = new HttpParams();
            body = body.set('Name', this.name);
            body = body.set('Phone', this.phone);
            body = body.set('Email', this.email);
            body = body.set('Subject', this.subject);
            body = body.set('Message', this.message);

            body = body.set('Page', 'Home Page');
            this.http.post("https://admin.osit.com.sa/api/contactus", body).subscribe((data) => {

                Report.success(
                    'تم إرسال الطلب',
                    'تم إرسال الطلب لنا و سيتم التواصل معك بأقرب وقت!',
                    'حسناً',
                );
                this.name = "";
                this.email = "";
                this.subject = "";
                this.message = "";
                this.phone = "";
            });

        }

    }

}
