import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { TopHeaderComponent } from './components/layouts/top-header/top-header.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { SubscribeComponent } from './components/layouts/subscribe/subscribe.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { TeamComponent } from './components/pages/team/team.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { NgParticlesModule } from "ng-particles";
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { ErpSolutionComponent } from './components/pages/erp-solution/erp-solution.component';
import { OdooSolutionComponent } from './components/pages/odoo-solution/odoo-solution.component';
import { OpenbravoSolutionComponent } from './components/pages/openbravo-solution/openbravo-solution.component';
import { HrSolutionComponent } from './components/pages/hr-solution/hr-solution.component';
import { PosSolutionComponent } from './components/pages/pos-solution/pos-solution.component';
import { HospitalSolutionComponent } from './components/pages/hospital-solution/hospital-solution.component';
import { LogisticsSoloutionComponent } from './components/pages/logistics-solutions/logistics-solutions.component';
import { OdooCustomizationComponent } from './components/pages/odoo-customization-support-service/odoo-customization-support-service.component';
import { WebApplicationComponent } from './components/pages/web-application-development-service/web-application-development-service.component';
import { MobileApplicationComponent } from './components/pages/mobile-application-development-service/mobile-application-development-service.component';
import { EcommerceDevelopmentComponent } from './components/pages/ecommerce-development-service/ecommerce-development-service.component';
import { CustomDevelopmentComponent } from './components/pages/custom-development-service/custom-development-service.component';
import { CloudServerComponent } from './components/pages/cloud-server-cyber-securities-service/cloud-server-cyber-securities-service.component';

import { ServiceComponent } from './components/pages/services/services.component';
import { SolutionComponent } from './components/pages/solutions/solutions.component';

import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { BlogsCategoryComponent } from './components/pages/blogsCategory/blogscategory.component';
import { CareerComponent } from './components/pages/career/career.component';
import { QuotationRequestComponent } from './components/pages/quotation-request/quotation-request.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
const cookieConfig: any = {
    cookie: {
        domain: 'osit.com.sa' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
        popup: {
            background: '#e6e6e6'
        },
        button: {
            background: '#00a09d'
        }
    },
    theme: 'edgeless',
    type: 'opt-out',
    content: {
        message: 'We use cookies to improve our service for you. You can find more information in our ',
        href: "privacy-policy",
        link: "Privacy Policy"
    }
};
@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        TopHeaderComponent,
        NavbarComponent,
        HomeTwoComponent,
        FooterComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        HomeSixComponent,
        SubscribeComponent,
        ContactComponent,
        TeamComponent,
        AboutComponent,
        ServicesOneComponent,
        ServicesTwoComponent,
        ServicesDetailsComponent,
        ProjectsOneComponent,
        ProjectsTwoComponent,
        ProjectsDetailsComponent,
        BlogOneComponent,
        BlogTwoComponent,
        BlogDetailsComponent,
        ErrorComponent,
        PricingComponent,
        ErpSolutionComponent,
        OdooSolutionComponent,
        OpenbravoSolutionComponent,
        HrSolutionComponent,
        PosSolutionComponent,
        HospitalSolutionComponent,
        LogisticsSoloutionComponent,
        OdooCustomizationComponent,
        WebApplicationComponent,
        MobileApplicationComponent,
        EcommerceDevelopmentComponent,
        CustomDevelopmentComponent,
        CloudServerComponent,
        ServiceComponent,
        SolutionComponent,
        BlogsComponent,
        BlogsCategoryComponent,
        CareerComponent,
        QuotationRequestComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgParticlesModule,
        HttpClientModule,
        FormsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgxGoogleAnalyticsModule.forRoot('G-2TR9B42TSM')
    ],
    providers: [DatePipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
