<div class="page-title animatedBackground">
    <div class="container">
        <h3>Team</h3>
        <ul>
            <li><a router="/">Home</a></li>
            <li class="dot-divider"></li>
            <li>Team</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="team-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team1.jpg" alt="team">
                    <div class="team-content">
                        <h3>Matthew Hunter</h3>
                        <span>CEO & Founder</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team2.jpg" alt="team">
                    <div class="team-content">
                        <h3>David Andy</h3>
                        <span>Marketing Head</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team3.jpg" alt="team">
                    <div class="team-content">
                        <h3>James Amith</h3>
                        <span>Team Lead</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team4.jpg" alt="team">
                    <div class="team-content">
                        <h3>Aston Agar</h3>
                        <span>Project Manager</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team2.jpg" alt="team">
                    <div class="team-content">
                        <h3>David Andy</h3>
                        <span>Marketing Head</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team3.jpg" alt="team">
                    <div class="team-content">
                        <h3>James Amith</h3>
                        <span>Team Lead</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team4.jpg" alt="team">
                    <div class="team-content">
                        <h3>Aston Agar</h3>
                        <span>Project Manager</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team1.jpg" alt="team">
                    <div class="team-content">
                        <h3>Matthew Hunter</h3>
                        <span>CEO & Founder</span>
                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>