<div class="page-title animatedBackground">
    <div class="container">
        <h3>خدماتنا</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>الخدمات</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-area bg ptb-100">
    <div class="container">
        <div class="section-title">
         <!--   <span>We work with you not for you</span> -->
            <h3>التحول الرقمي للأعمال.</h3>
            <p>نقدم استشارات تقنية لتصميم و تطويرالبرمجيات ومع توسيع نطاق تطبيقات المواقع والجوال لجميع الأنظمة مع حلول برمجية مخصصة تناسب متطلباتك لتحقق طريق نجاحاتك!</p>
           
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-automation"></i>
                    </div>
                    <h3>Odoo للتطوير والدعم</h3>
                    <p>تطوير يشمل جميع الاعمال و الصناعات</p>
                    <a routerLink="/odoo-customization-and-support-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-dashboard-web"></i>
                    </div>
                    <h3>تطوير تطبيقات المواقع</h3>
                    <p>خدمات تطوير الويب الرائدة لتعزيز نمو أعمالك</p>
                    <a routerLink="/web-application-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-stock-mobile"></i>
                    </div>
                    <h3>تطوير تطبيقات الجوال</h3>
                    <p>تطوير تطبيقات الجوال  بشكل احترافي تناسب جميع الانظمة</p>
                    <a routerLink="/mobile-application-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-shopping-cart"></i>
                    </div>
                    <h3>تطوير التجارة الإلكترونية</h3>
                    <p>خدمات تطوير التجارة الإلكترونية الشاملة في أي إطار عمل</p>
                    <a routerLink="/ecommerce-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-code"></i>
                    </div>
                    <h3>تطوير حلول برمجية مخصصة</h3>
                    <p>تطوير مخصص لأي متطلب في أي إطار عمل</p>
                    <a routerLink="/custom-development-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-cloudapp"></i>
                    </div>
                    <h3>الخدمات السحابية والأمن السيبراني</h3>
                    <p>الخدمات السحابية والأمن السيبراني هي متطلب أساسي لكل مؤسسة</p>
                    <a routerLink="/cloud-server-and-cyber-securities-service" class="read-more-btn">اقرأ اكثر</a>
                </div>
            </div>
        </div>
    </div>
</section>

