import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-logistics-solutions',
  templateUrl: './logistics-solutions.component.html',
  styleUrls: ['./logistics-solutions.component.scss']
})
export class LogisticsSoloutionComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'استكشف حلاً لإدارة اللوجستيات في نظامنا لإدارة اللوجستيات في أوسيت. سهل عمليات سلسلة التوريد واللوجستيات الخاصة بك مع برمجياتنا وخدماتنا المتقدمة. قم بتعزيز الكفاءة، وتقليل التكاليف، وتحسين عمليات اللوجستيات بأكملها في المملكة العربية السعودية. اكتشف كيف يمكن أن تحول خبرتنا إدارة اللوجستيات الخاصة بك.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
