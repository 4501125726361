<div class="page-title animatedBackground">
    <div class="container">
        <h3>الخدمات السحابية والأمن السيبراني</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/services">الخدمات</a></li>
            <li class="dot-divider"></li>
            <li>الخدمات السحابية والأمن السيبراني</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>اكتشف ما يمكن أن تفعله مع خدمات الحوسبة السحابية من OSIT.</h3>
                <p>نقوم ببناء نموذج الحوسبة السحابية للشركات الناشئة والصغيرة والمتوسطة والمؤسسات. تتمتع تطبيقات الحوسبة السحابية ببنية تحتية ديناميكية توفر العديد من الفوائد لأي نوع من المؤسسات من خلال تمكين المستخدمين من التحكم في موارد الحوسبة المشتركة الخاصة بهم. بامكانية تقليل التكاليف التشغيلية وتمكين استخدامها من أي جهاز و أينما كنت وزيادة الأداء إلى أقصى حد وتحسين الأمان باستخدام الحوسبة السحابية لمؤسستك.</p>
               <p>&nbsp;<br></p>
                <h5>متجر شامل للحوسبة السحابية:</h5>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> برامج الحوسبة السحابية</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> الحوسبة السحابية SaaS</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> حلول السحابة الخاصة</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> نوفر المنصة كخدمة PaaS</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> نوفر الحوسبة السحابية</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> نوفر التخزين السحابي</h4>
                </div>
            </div>

            <div class="services-details-image">
                <img src="assets/img/slide2.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/fullbg3.png" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>استشارات الأمن السيبراني</h3>
                <p>نحن متخصصون في تقييم الأمن السيبراني والتصميم والتنفيذ والدعم والخدمات المدارة للشركات الصغيرة والمتوسطة مع مراكز البيانات المحلية. وتشمل اعمالنا:</p>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> أمان مركز البيانات (خارجي / داخلي)</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> أمن الشبكة</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> أمان نقطة النهاية (العميل / الخادم)</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> أمان نظام حاسوب او شبكة (الويب والجوال والشبكة)</h4>
                </div>
                <div class="features-text">
                    <h4><i class="icofont-check-alt"></i> خدمات الأمن المدارة</h4>
                </div>
                <p>نوفر أيضًا مركز عمليات الأمان (SOC) كجزء من خدمات مركز التحكم المُدار.</p>
            </div>
        </div>
    </div>
</section>

