import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-server-cyber-securities-service',
  templateUrl: './cloud-server-cyber-securities-service.component.html',
  styleUrls: ['./cloud-server-cyber-securities-service.component.scss']
})
export class CloudServerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
