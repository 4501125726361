<div class="page-title animatedBackground">
    <div class="container">
        <h3>نظام ادارة المستشفيات</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li><a routerLink="/our-solutions">الحلول</a></li>
            <li class="dot-divider"></li>
            <li>نظام ادارة المستشفيات</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>نظام ادارة المستشفيات</h3>
                <p>هو برنامج نظام إدارة مستشفيات مصمم لإدارة جميع عمليات المستشفى من جميع الجوانب الإدارية والطبية والمالية والهدف الرئيسي هو تنظيم العمل في المستشفى مما يتيح الوصول الى بيانات المريض ومتابعته و يعد حلاً متكاملًا للرعاية الصحية الكاملة  يتضمن إدارة العيادات الخارجية و IPD والصيدلة والمختبروالأشعة وإدارة الأقسام وتطبيق الهاتف المحمول وجدولة المواعيد عبر الإنترنت  والرسائل الآمنة  وبوابة الطبيب وبوابات المريض والأسرة  والفواتير الإلكترونية الطبية  والمحاسبة و الموارد البشرية / كشوف المرتبات و HL7 / نظام PACS المتكامل.  وهو نظام الكتروني  متكامل يعمل على خدمة متكاملة لتحسين كفاءة وفعالية الرعاية  الصحية.</p>
                
            </div>

            <div class="services-details-image">
                <img src="assets/img/hospital.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image">
                <img src="assets/img/hospital-management-3.jpg" style="border-radius:25px ;" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>الفوائد الرئيسية</h3>
                <div class="features-text">
                
                    <p><i class="icofont-check-alt"></i>سهولة الوصول إلى بيانات الأطباء لإنشاء سجلات متنوعة بما في ذلك التصنيف على أساس الموقع السكني والجنس والعمر وما إلى ذلك. أيضا مفيد بشكل خاص في نقطة الإسعاف (العيادة الخارجية) وبالتالي تعزيز استمرارية الرعاية.</p>
                    <p><i class="icofont-check-alt"></i>يساعد كنظام دعم القرار لإدارة المستشفى لوضع سياسات رعاية صحية شاملة.</p>
                    <p><i class="icofont-check-alt"></i>إدارة فعالة ودقيقة للمالية ونظام غذائي للمريض وتوزيع المساعدات الطبية. يساعد على عرض صورة واسعة لنمو المستشفى.</p>
                    <p><i class="icofont-check-alt"></i>تحسين مراقبة تعاطي الادوية ودراسة فعاليتها. هذا يؤدي إلى الحد من التفاعلات الدوائية الضارة مع تعزيز الاستخدام الصيدلاني الأكثر ملاءمة.</p>
                    <p><i class="icofont-check-alt"></i>يعزز تكامل المعلومات ويقلل من أخطاء النسخ ويقلل من تكرار إدخالات المعلومات.</p>
                    <p><i class="icofont-check-alt"></i>برنامج المستشفى سهل الاستخدام ويزيل الأخطاء الناتجة عن الكتابة اليدوية. توفر أنظمة الكمبيوتر ذات التقنية الجديدة أداءً مثاليًا لسحب المعلومات من الخادم المحلي أو الخوادم السحابية.</p>
              
                </div>
             
            </div>
        </div>
    </div>
</section>

