import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-odoo-solution',
  templateUrl: './odoo-solution.component.html',
  styleUrls: ['./odoo-solution.component.scss']
})
export class OdooSolutionComponent implements OnInit {

  
  constructor(private meta: Meta) {

    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.meta.addTags([ 
      { name: 'description', content: 'اكتشف حلول أودو الشاملة لدينا في أوسيت. نحن متخصصون في تنفيذ وتخصيص أنظمة أودو ERP لتبسيط عمليات أعمالك في الرياض، المملكة العربية السعودية. استكشف كيف يمكن أن تعزز خبرتنا في أودو كفاءة ونمو شركتك.' }, 
      { name: 'keywords', content: 'osit,it,saudi it,software development,website development' } 
  ]);
   }

  ngOnInit() {
  }

}
