<div class="page-title animatedBackground">
    <div class="container">
        <h3>سياسة الخصوصية</h3>
        <ul>
            <li><a routerLink="/">الصفحة الرئيسية</a></li>
            <li class="dot-divider"></li>
            <li>سياسة الخصوصية</li>
        </ul>
    </div>
    <div class="bg-pattern"><img src="assets/img/bg-pattern-2.png" alt="pattern"></div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                   
                    <div class="blog-details-content">
                    
                     <h4> المعلومات الشخصية </h4>
                        <br>
                       <h5> جمع واستخدام المعلومات الشخصية الخاصة بك </h5>
                        
                   <p>     كجزء من خدمتنا، نقوم بجمع المعلومات من مستخدمي موقعنا وعملائنا والمتقدمين الموهوبين للانضمام إلينا. نجمع معلوماتك الشخصية ونستخدمها من أجل تشغيل موقعنا على الويب وتزويدك بالخدمات التي تطلبها. نحن لا نبيع أو نؤجر قوائم عملائنا لأي شخص. قد تشارك OSIT معلوماتك مع جهات موثوقة للمساعدة في التحليل الإحصائي، وإرسال رسائل إخبارية بالبريد الإلكتروني، وتوفير خدمة العملاء، وما إلى ذلك. وقد نتتبع الصفحات التي يزورها المستخدمون من أجل تقييم خدمات OSIT الأكثر شيوعًا. يتم إرسال محتوى مخصص وإعلانات للعملاء الذين يشير نشاطهم إلى أنهم مهتمون بموضوع معين على OSIT. لن نكشف عن معلوماتك الشخصية إلا إذا طُلب ذلك بموجب القانون أو بحسن نية. 
                    </p>
                    <p>&nbsp;<br></p>
                     <h4>   ملف تعريف الإرتباط (Cookies) </h4>
                     <br>
                    <h5>    سياسة ملف تعريف الإرتباط (Cookies Policy) </h5>
                        
                  <p>      ملفات تعريف الارتباط هي ملفات بيانات صغيرة قد تحتوي أو لا تحتوي على تعريف فريد مجهول الهوية. يرسل موقع الويب ملفات تعريف الارتباط إلى متصفحك والتي يتم نقلها بعد ذلك إلى جهازك. تُستخدم ملفات تعريف الارتباط لجمع البيانات حتى نتمكن من تحسين خدماتنا لك. يمكنك ضبط المتصفح الخاص بك لرفض جميع ملفات تعريف الارتباط أو لإعلامك عند تلقي أحد ملفات تعريف الارتباط. تحتوي العديد من المتصفحات على ميزة المساعدة التي ستخبرك بكيفية قبول ملفات تعريف الارتباط أو تعطيلها أو إعلامك عند تلقي ملف تعريف ارتباط جديد. قد لا تتمكن من الوصول إلى بعض جوانب خدمتنا إذا لم تسمح بملفات تعريف الارتباط ، لذلك نوصي بتركها قيد التشغيل. 
                </p>
                <p>&nbsp;<br></p>
                <h4>  المصادر </h4>
                <br>
                <h5>    الرسائل الإخبارية والمدونات </h5>
                        
                <p>  نحن نوفر فرصًا للاشتراك في نشراتنا الإخبارية عبر البريد الإلكتروني التي يتم إرسالها بشكل دوري. يمكنك الاشتراك فيه من خلال مشاركة عنوان بريدك الإلكتروني والتأكيد من خلال النقر على رابط التأكيد المرسل إليك. في هذه العملية نجمع عنوان بريدك الإلكتروني وعنوان IP ووقت الاشتراك. نحن نستخدم هذه المعلومات بسرية لتوزيع الرسائل الإخبارية عبر البريد الإلكتروني فقط. يمكنك إلغاء الاشتراك في أي وقت بمساعدة تعليمات إلغاء الاشتراك المتبعة في كل رسالة إخبارية. بمجرد إلغاء الاشتراك يتم حذف جميع المعلومات الشخصية نهائيًا من نظامنا. نقوم بشكل دوري بنشر المدونات على موقعنا والتي يتم الوصول إليها بشكل عام. يمكنك التعليق بحرية على تعليقاتك أو اقتراحاتك أو بدء المناقشات في قسم التعليقات في المدونات. نريد إبلاغك بأن أي معلومات تشاركها في التعليقات يمكن قراءتها أو مشاركتها أو جمعها من قبل أي شخص يقرأها. 
                        </p>
                        <p>&nbsp;<br></p>
                        <h4>    التحليلات </h4>
                        <br>
                        <h5>     تحليلات جوجل (Google Analytics) </h5>
                        
                        <p>   في OSIT نستخدم Google Analytics من أجل مراقبة وتحليل كيفية تفاعل المستخدمين مع موقعنا. يساعدنا ذلك في تحسين جودة الخدمات والمحتوى والاتصالات التي نقدمها لمستخدمينا. نود أن نؤكد لك أنه لا يتم تخزين أي بيانات شخصية في هذه العملية ويمكنك التحقق من ذلك باستخدام سياسة Google Analytics أيضًا. 
                        </p>
                        <p>&nbsp;<br></p>
                        <h4>  الموافقة والقبول </h4>
                        <br>
                        <h5>       قبول السياسة  </h5>
                        
                        <p>   أنت توافق على سياسة الخصوصية الخاصة بنا عن طريق الوصول إلى هذا الموقع أو استخدام الخدمات التي يوفرها هذا الموقع. يرجى عدم استخدام موقعنا أو الخدمات التي يقدمها هذا الموقع إذا كنت لا توافق على أي من الشروط. نحتفظ أيضًا بالحق وفقًا لتقديرنا في تعديل أو إضافة أو إزالة عناصر من هذه السياسة ببساطة عن طريق نشر هذه التغييرات أو التحديثات أو التعديلات على موقعنا. يشير استمرار استخدام موقعنا بعد تغيير شروط السياسة إلى موافقتك على هذه الشروط. 
                        </p>
                        <p>&nbsp;<br></p>
                        <h4>  التحديثات </h4>
                        
                        <p>تم اجراء آخر تحديث لسياسة الخصوصية في أغسطس 2022. </p>
                    </div>
                </div>
            </div>

        
        </div>
    </div>
</section>

